import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Adult Redux States
import { ADD_EXTERNAL_PARENT, VERIFY_EXTERNAL_PARENT } from "./actionTypes"
import {
  addExternalParentSuccess,
  addExternalParentFail,
  verifyExternalParentSuccess,
  verifyExternalParentFail,
} from "./actions"

//Include Both Helper File with needed methods
import { addExternalParent, verifyExternalParent } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* onAddExternalParent({ payload: parent }) {
  try {
    const response = yield call(addExternalParent, parent)
    yield put(addExternalParentSuccess(response))
  } catch (error) {
    yield put(addExternalParentFail(error))
  }
}

function* onVerifyExternalParent({ payload: rowid }) {
  try {
    const response = yield call(verifyExternalParent, rowid)
    yield put(verifyExternalParentSuccess(response))
  } catch (error) {
    yield put(verifyExternalParentFail(error))
  }
}

function* externalSaga() {
  yield takeEvery(ADD_EXTERNAL_PARENT, onAddExternalParent)
  yield takeEvery(VERIFY_EXTERNAL_PARENT, onVerifyExternalParent)
}

export default externalSaga
