import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { isEmpty } from "lodash"
import { useDeepCompareEffect } from "hooks"
import classnames from "classnames"
import { verifyExternalParent as onVerifyExternalParent } from "store/actions"
import AlertContainer from "components/Common/AlertContainer"

const ParentConfirmation = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const { parent, error } = useSelector(state => state.external)

  //meta title
  document.title = "Confirmation | " + process.env.REACT_APP_NAME

  useDeepCompareEffect(() => {
    const { id } = routeParams
    if (id !== "") {
      dispatch(onVerifyExternalParent(id))
    }
  }, [dispatch, routeParams])

  const onCancelClick = () => {
    navigate("/login")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>
                    Welcome{" "}
                    {(parent?.FirstName ?? "") + " " + (parent?.LastName ?? "")}
                    !
                  </CardTitle>
                  <Row>
                    <Col>
                      {error?.data}
                      <p hidden={isEmpty(parent)}>
                        {parent && parent.LinkExpired && parent.Verified
                          ? "Verification link has expired. <br /> Account has already been activated."
                          : ""}
                      </p>
                      <p hidden={isEmpty(parent)}>
                        {parent && parent.Verified
                          ? " Your account has been successfully activated."
                          : ""}
                      </p>
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="secondary"
                      onClick={onCancelClick}
                      className=""
                    >
                      Back to Login
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ParentConfirmation)
