import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"
import classnames from "classnames"

import { getAuthUser } from "helpers/token_helper"

import {
  getProfile as onGetProfile,
  getWalletTransaction as onGetWalletTransaction,
  getFeeTransactionDetail as onGetFeeTransactionDetail,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { isEmpty } from "lodash"
import MoneyTab from "../tabs/MoneyTab"
import PaymentTab from "../tabs/PaymentTab"

function PaymentPage() {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})
  const [tabValue, setTabValue] = useState("1")
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title ="Money | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (!isEmpty(userprofile)) {
      dispatch(onGetWalletTransaction(userprofile.UserID))
      dispatch(onGetFeeTransactionDetail(userprofile.UserID))
    }
  }, [userprofile])

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Payments" breadcrumbItem="Money" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {NavItemTab("Payments", "1")}
                    {NavItemTab("Money", "2")}
                  </Nav>
                  <TabContent activeTab={tabValue} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <PaymentTab />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <MoneyTab />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentPage
