import React, { useEffect, useState, Fragment, useMemo } from "react"
import {
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Card,
  CardBody,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
// import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import { getMealSchedules as onGetMealSchedules } from "store/actions"
import Spinners from "components/Common/Spinner"
import { useDeepCompareEffect } from "hooks"

function FoodMenu() {
  const dispatch = useDispatch()
  const [mealScheduleID, setMealScheduleID] = useState(0)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [filter, setFilter] = useState({
    date: Moment().format("YYYY-MM-DD"),
  })
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title ="Food Menu | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataList, setDataList] = useState()
  const perPageData = 5
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  useDeepCompareEffect(() => {
    dispatch(onGetMealSchedules(filter))
  }, [dispatch, filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////

  //const { data, loading, column } = useSelector(state => state.mealSchedules)
  const selectMealScheduleState = state => state.mealSchedules
  const MealScheduleProperties = createSelector(selectMealScheduleState, e => ({
    data: e.mealSchedules,
    loading: e.loading,
    column: e.column,
  }))
  const { data, loading, column } = useSelector(MealScheduleProperties)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  function handleDate(param) {
    setFilter({ date: param })
  }

  const currentdata = useMemo(
    () => data ?? data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  )

  useEffect(() => {
    setDataList(currentdata)
  }, [currentdata])

  const toggleTooltip = id => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Food" breadcrumbItem="Food Menu" />
          <Row className="mb-2">
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .subtract(7, "days")
                              .format("YYYY-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio4"
                      >
                        <i className="fas fa-angle-left" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio5"
                        autoComplete="off"
                        defaultChecked
                        onClick={() =>
                          handleDate(Moment().format("YYYY-MM-DD"))
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio5"
                      >
                        Today
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio6"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .add(7, "days")
                              .format("YYYY-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio6"
                      >
                        <i className="fas fa-angle-right" />
                      </label>
                    </div>
                  </div>
                  <Table className="align-middle table-nowrap table-check table-bordered">
                    <thead className="table-light">
                      <tr>
                        {column.map((row, index) => (
                          <th
                            key={"th" + index}
                            className={row === "MealID" ? "d-none" : ""}
                          >
                            <div className="m-0">{row}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((n, index) => {
                        return (
                          <Fragment key={"dl" + index}>
                            <tr style={{ height: "100px" }}>
                              {column.map((v, index) => {
                                return (
                                  <td
                                    key={"clm" + index}
                                    className={
                                      v === "ChildID" ? "d-none" : "align-top"
                                    }
                                  >
                                    {/* {console.log("Inner Loop", n[v])} */}
                                    {v !== "Name" &&
                                    v !== "ChildID" &&
                                    v !== "id" &&
                                    n[v] !== "" &&
                                    n[v] !== undefined
                                      ? JSON.parse(n[v]).map((w, i) => (
                                          <div
                                            className="border rounded p-2 mb-2 w-100 cursor-pointer"
                                            key={"dv" + w.MealSchedulingID}
                                          >
                                            <h6>{w.Title}</h6>
                                            <span>{w.MealOptions}</span>
                                           
                                          </div>
                                        ))
                                      : n[v]}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
export default FoodMenu
