import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Card,
  CardBody,
} from "reactstrap"

// import { ToastContainer } from "react-toastify"
import {
  getReportDetail as onGetReportDetail,
  getReportData as onGetReportData,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { useDeepCompareEffect } from "hooks"
import Flatpickr from "react-flatpickr"
import Moment from "moment"
import Spinners from "components/Common/Spinner"
import {
  ChildrenDailyColumns,
  ChildrenWeeklyColumns,
  StaffDailyColumns,
  ChildrenAttendanceColumns,
  ChildrenAllergiesColumns,
  ChildrenEmergencyContactsColumns,
  StaffEmergencyContactsColumns,
  StaffWeeklyColumns,
  AbsentChildrenColumns,
  AbsentStaffColumns,
  UnpaidServicesColumns,
  FinanceColumns,
  WalletBalanceColumns,
  WalletColumns,
  PaymentColumns,
  ProgramChargesColumns,
  DocExpColumns,
  TagExpChildrenColumns,
  ChargesColumns,
  HourlyCareChargesColumns,
  AddonChargesColumns,
  OtherChargesColumns,
  CreditDiscountChargesColumns,
} from "./columns"

import {
  ChildrenDailyColumnsForExport,
  ChildrenWeeklyColumnsForExport,
  StaffDailyColumnsForExport,
  ChildrenAttendanceColumnsForExport,
  ChildrenAllergiesColumnsForExport,
  ChildrenEmergencyContactsColumnsForExport,
  StaffEmergencyContactsColumnsForExport,
  StaffWeeklyColumnsForExport,
  AbsentChildrenColumnsForExport,
  AbsentStaffColumnsForExport,
  UnpaidServicesColumnsForExport,
  FinanceColumnsForExport,
  WalletBalanceColumnsForExport,
  WalletColumnsForExport,
  PaymentColumnsForExport,
  ProgramChargesColumnsForExport,
  DocExpColumnsForExport,
  TagExpChildrenColumnsForExport,
  ChargesColumnsForExport,
  HourlyCareChargesColumnsForExport,
  AddonChargesColumnsForExport,
  OtherChargesColumnsForExport,
  CreditDiscountChargesColumnsForExport,
} from "./columnsForExport"

function ShowReport() {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [isInIt, setIsInit] = useState(true)
  const [isfilterDate, setIsFilterDate] = useState(true)
  const [filterDate, setFilterDate] = useState(Moment().format("DD-MMM-yyyy"))
  const [filterEndDate, setFilterEndDate] = useState(
    Moment().format("DD-MMM-yyyy")
  )
  const [filterColumns, setFilterColumns] = useState([])
  const [exportColumns, setExportColumns] = useState([])
  const [isDailyReport, setIsDailyReport] = useState(false)
  const [showDate, setShowDate] = useState(true)

  const { id } = routeParams

  useDeepCompareEffect(() => {
    dispatch(onGetReportDetail(id))
  }, [dispatch])

  const { reportdetail, data, loading } = useSelector(state => state.report)
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    debugger
    if (reportdetail?.RowID === id && isInIt) {
      const { reportColumns, exportColumns } = renderSwitch(
        reportdetail.ReportID
      )
      setFilterColumns(reportColumns)
      setExportColumns(exportColumns)
      dispatch(
        onGetReportData(reportdetail.ReportID, filterDate, filterEndDate)
      )
    }
  }, [reportdetail, filterDate, filterEndDate, isInIt, id, dispatch])

  function renderSwitch(reportID) {
    const reportMappings = {
      1: {
        reportColumns: ChildrenDailyColumns,
        exportColumns: ChildrenDailyColumnsForExport,
        daily: true,
      },
      2: {
        reportColumns: StaffDailyColumns,
        exportColumns: StaffDailyColumnsForExport,
        daily: true,
      },
      3: {
        reportColumns: ChildrenWeeklyColumns,
        exportColumns: ChildrenWeeklyColumnsForExport,
        daily: false,
      },
      4: {
        reportColumns: ChildrenAttendanceColumns,
        exportColumns: ChildrenAttendanceColumnsForExport,
        daily: false,
      },
      5: {
        reportColumns: ChildrenAllergiesColumns,
        exportColumns: ChildrenAllergiesColumnsForExport,
        date: false,
      },
      6: {
        reportColumns: ChildrenEmergencyContactsColumns,
        exportColumns: ChildrenEmergencyContactsColumnsForExport,
        date: false,
      },
      7: {
        reportColumns: StaffEmergencyContactsColumns,
        exportColumns: StaffEmergencyContactsColumnsForExport,
        date: false,
      },
      8: {
        reportColumns: StaffWeeklyColumns,
        exportColumns: StaffWeeklyColumnsForExport,
        daily: false,
      },
      9: {
        reportColumns: AbsentChildrenColumns,
        exportColumns: AbsentChildrenColumnsForExport,
        daily: false,
      },
      10: {
        reportColumns: AbsentStaffColumns,
        exportColumns: AbsentStaffColumnsForExport,
        daily: false,
      },
      11: {
        reportColumns: UnpaidServicesColumns,
        exportColumns: UnpaidServicesColumnsForExport,
        date: false,
      },
      12: {
        reportColumns: FinanceColumns,
        exportColumns: FinanceColumnsForExport,
        daily: false,
      },
      13: {
        reportColumns: FinanceColumns,
        exportColumns: FinanceColumnsForExport,
        daily: false,
      },
      14: {
        reportColumns: WalletBalanceColumns,
        exportColumns: WalletBalanceColumnsForExport,
        daily: true,
      },
      15: {
        reportColumns: ProgramChargesColumns,
        exportColumns: ProgramChargesColumnsForExport,
        daily: false,
      },
      16: {
        reportColumns: WalletColumns,
        exportColumns: WalletColumnsForExport,
        daily: false,
      },
      17: {
        reportColumns: PaymentColumns,
        exportColumns: PaymentColumnsForExport,
        daily: false,
      },
      18: {
        reportColumns: DocExpColumns,
        exportColumns: DocExpColumnsForExport,
        date: false,
      },
      19: {
        reportColumns: DocExpColumns,
        exportColumns: DocExpColumnsForExport,
        date: false,
      },
      20: { reportColumns: [], exportColumns: [], date: false }, // No columns defined
      21: {
        reportColumns: TagExpChildrenColumns,
        exportColumns: TagExpChildrenColumnsForExport,
        date: false,
      },
      22: { reportColumns: [], exportColumns: [], date: false }, // No columns defined
      23: {
        reportColumns: ChargesColumns,
        exportColumns: ChargesColumnsForExport,
        daily: false,
      },
      24: {
        reportColumns: HourlyCareChargesColumns,
        exportColumns: HourlyCareChargesColumnsForExport,
        daily: false,
      },
      25: {
        reportColumns: AddonChargesColumns,
        exportColumns: AddonChargesColumnsForExport,
        daily: false,
      },
      26: {
        reportColumns: OtherChargesColumns,
        exportColumns: OtherChargesColumnsForExport,
        daily: false,
      },
      27: {
        reportColumns: CreditDiscountChargesColumns,
        exportColumns: CreditDiscountChargesColumnsForExport,
        daily: false,
      },
    }

    const mapping = reportMappings[reportID] || {}
    setIsDailyReport(mapping.daily || false)
    setShowDate(mapping.date !== false)
    return {
      reportColumns: mapping.reportColumns || [],
      exportColumns: mapping.exportColumns || [],
    }
  }

  const columns = useMemo(() => filterColumns, [filterColumns])
  const columnsForExport = useMemo(() => exportColumns, [exportColumns])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Reports" breadcrumbItem="Show Report" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row className="mb-2 justify-content-between">
                      <Col sm="6">
                        <h4>{reportdetail.ReportTitle}</h4>
                        {showDate ? (
                          <h5 className="text-muted">
                            {isDailyReport
                              ? Moment(filterDate).format("DD MMMM, Y")
                              : filterDate.toString() ===
                                filterEndDate.toString()
                              ? Moment(filterDate).format("DD MMMM, Y")
                              : Moment(filterDate).format("DD MMMM, Y") +
                                " - " +
                                Moment(filterEndDate).format("DD MMMM, Y")}
                          </h5>
                        ) : (
                          ""
                        )}
                        <div className="badge rounded-1 badge-soft-success mb-3">
                          {reportdetail.ReportType}
                        </div>
                      </Col>
                      {showDate ? (
                        <Col sm="6" lg={isDailyReport ? 2 : 3}>
                          <div className="mb-3 d-flex">
                            <Flatpickr
                              hidden={!isfilterDate}
                              className="form-control me-3"
                              id="FilterDate"
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              value={filterDate}
                              onChange={(selectedDates, dateStr, instance) => {
                                setFilterDate(dateStr)
                                setFilterEndDate(dateStr)
                              }}
                            />
                            <Flatpickr
                              hidden={isDailyReport || !isfilterDate}
                              className="form-control me-3"
                              id="FilterEndDate"
                              options={{
                                dateFormat: "d M, Y",
                                minDate: filterDate,
                              }}
                              value={filterEndDate}
                              onChange={(selectedDates, dateStr, instance) => {
                                setFilterEndDate(dateStr)
                              }}
                              disabled={!filterDate}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <div>
                      <TableContainer
                        columns={columns}
                        columnsForExport={columnsForExport}
                        data={data}
                        isGlobalFilter={true}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded mb-0 ms-auto"
                        isExportOption={true}
                        headerTitle={reportdetail.ReportTitle}
                        tableID={reportdetail.reportID}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
ShowReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ShowReport
