import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { getBasicSettings as onGetBasicSettings } from "store/actions"
import { setFavicon } from "./helpers/basic_setting_helper" // Path to the helper file

// Import scss
import "./assets/scss/theme.scss"
// Import custom css
import "./assets/css/custom.css"
//custom hooks
import { useDeepCompareEffect } from "hooks"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const dispatch = useDispatch()
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)
  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting, loading } = useSelector(state => state.Layout)
  //const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetBasicSettings())
  }, [dispatch])
  useEffect(() => {
    setFavicon(basicSetting)
    //  document.title = `LOGIN | ${basicSetting?.SiteName}` // Dynamically set the page title
  }, [dispatch, basicSetting])
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
