import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import Flatpickr from "react-flatpickr"

import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import GenderField from "components/Common/Gender/Gender"
import { useDeepCompareEffect } from "hooks"
import classnames from "classnames"
import LocationField from "components/Common/Location/Location"
import {
  TextInput,
  DropdownInput,
  NumberInput,
  DateInput,
} from "components/Common/Controls"
import {
  getGenders as onGetGenders,
  addExternalParent as onAddExternalParent,
} from "store/actions"

const RegisterExternalParent = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const { genders } = useSelector(state => state.staff)
  const { success } = useSelector(state => state.external)

  //meta title
  document.title = "Registration | " + process.env.REACT_APP_NAME

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    EmailAddress: yup
      .string()
      .required("Required")
      .email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    DateOfBirth: yup.string(),
    Address: yup.string(),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object(),
    Genders: yup.object().required("Required"),
    ZipCode: yup.string().max(10, "Zipcode should be max 10 characters"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      DateOfBirth: "",
      Address: "",
      ZipCode: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    const { id } = routeParams
    dispatch(onGetGenders())
  }, [dispatch, routeParams])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(onAddExternalParent(getValues()))
    }
  }

  const onCancelClick = () => {
    navigate("/login")
  }

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card hidden={success}>
                  <CardBody>
                    <CardTitle>Register Parent</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <TextInput
                            label="First Name"
                            id="FirstName"
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <TextInput
                            label="Last Name"
                            id="LastName"
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <DropdownInput
                            label="Gender"
                            id="Genders"
                            required={true}
                            items={genders}
                            itemlabel="Gender"
                            itemvalue="GenderID"
                          />
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <TextInput
                            label="Email"
                            id="EmailAddress"
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <NumberInput
                            label="Phone Number"
                            id="PhoneNumber"
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <DateInput
                            label="Date of Birth"
                            id="DateOfBirth"
                            required={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        <LocationField />
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        color="primary"
                        className="btn"
                        onClick={handleSave}
                      >
                        Submit
                      </Button>
                      <Button
                        type="submit"
                        color="secondary"
                        onClick={onCancelClick}
                        className=""
                      >
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Card hidden={!success}>
                  <CardBody>
                    <CardTitle>Email verification required</CardTitle>
                    <p>
                      An email has been sent to{" "}
                      <b>{getValues("EmailAddress") ?? "##@##.com"}</b> to
                      verify the email address. Please note it may take
                      <b>up to 15 minutes</b> for the email to arrive in your
                      mail box.
                    </p>
                    <p>
                      If you cannot see the email in your <b>Inbox</b>, please
                      also check your <b>Junk</b>
                      and <b>Spam</b> folders.
                    </p>
                    <p>
                      Upon clicking the link in the email, your account has been
                      approved.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div></div>
      </FormProvider>
    </React.Fragment>
  )
}

export default withRouter(RegisterExternalParent)
