import {
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_DETAIL_SUCCESS,
  GET_SUBSCRIPTION_DETAIL_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  RESET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_TYPES_FAIL,
  GET_SUBSCRIPTION_TYPES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  subscriptions: [],
  subscription: {},
  subscriptiontypes:[],
  error: {},
  loading: true,
}

const Subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        loading: true,
      }
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_SUBSCRIPTION_SUCCESS: {
      const data = {
        ...state,
        subscriptions: state.subscriptions.filter(
          subscription => subscription.SubscriptionID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        subscriptions: state.subscriptions.filter(subscription => subscription.SubscriptionID !== action.payload),
      }
    }
    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
      }
    case ADD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SUBSCRIPTION_DETAIL_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        loading: true,
      }

    case GET_SUBSCRIPTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: state.subscription.map(subscription =>
          subscription.SubscriptionID.toString() === action.payload.SubscriptionID.toString()
            ? { subscription, ...action.payload }
            : subscription
        ),
      }

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
      }
      case GET_SUBSCRIPTION_TYPES_SUCCESS:
        return {
          ...state,
          subscriptiontypes: action.payload,
          loading: true,
        }
      case GET_SUBSCRIPTION_TYPES_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    default:
      return state
  }
}

export default Subscription
