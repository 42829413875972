import React from "react"
import { Container, Row, Col } from "reactstrap"
//redux
import { useSelector } from "react-redux"
const Footer = () => {
  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} ©{" "}
              {basicSetting?.SiteName || process.env.REACT_APP_NAME}.
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by {basicSetting?.CompanyName || process.env.REACT_APP_COMPANY_NAME}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
