import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Meal Redux States
import {
  GET_MEAL_SCHEDULES,
  RESET_MEAL_SCHEDULES,
  ADD_MEAL_SCHEDULES,
  DELETE_MEAL_SCHEDULE,
} from "./actionTypes"
import {
  getMealSchedulesSuccess,
  getMealSchedulesFail,
  resetMealScheduleSuccess,
  addMealScheduleSuccess,
  addMealScheduleFail,
  deleteMealScheduleSuccess,
  deleteMealScheduleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMealSchedules,
  addMealSchedule,
  deleteMealSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchMealSchedules({ filter }) {
  try {
    const response = yield call(getMealSchedules, filter)
    yield put(getMealSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getMealSchedulesFail(error))
  }
}

function* onAddMealSchedule({ payload: mealSchedules }) {
  debugger
  try {
    const request = {
      ...mealSchedules,
      MealID: mealSchedules.Meals?.MealID,
      ChildID: mealSchedules.Children?.ChildID,
      AbsentTypeID: mealSchedules.AbsentTypes?.AbsentTypeID ?? null,
    }
    const response = yield call(addMealSchedule, request)
    yield put(addMealScheduleSuccess(response.Table))
    toast.success("Meal schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addMealScheduleFail(error))
    toast.error("Meal schedule addition failed", { autoClose: 2000 })
  }
}

function* onResetMealSchedules() {
  try {
    yield put(resetMealScheduleSuccess({}))
  } catch (error) {}
}

function* onDeleteMealSchedule({ payload: mealSchedules }) {
  try {
    const response = yield call(deleteMealSchedule, mealSchedules)
    yield put(deleteMealScheduleSuccess(response.Table))
    toast.success("Meal schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteMealScheduleFail(error))
    toast.error("Meal schedule deletion failed", { autoClose: 2000 })
  }
}

function* mealScheduleSaga() {
  yield takeEvery(GET_MEAL_SCHEDULES, fetchMealSchedules)
  yield takeEvery(RESET_MEAL_SCHEDULES, onResetMealSchedules)
  yield takeEvery(ADD_MEAL_SCHEDULES, onAddMealSchedule)
  yield takeEvery(DELETE_MEAL_SCHEDULE, onDeleteMealSchedule)
}

export default mealScheduleSaga
