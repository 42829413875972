import PropTypes, { object } from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  getChildren as onGetChildren,
  getStaffs as onGetStaffs,
  addDoctorSchedule as onAddDoctorSchedule,
} from "store/actions"
import AlertContainer from "components/Common/AlertContainer"
import { EnumRoleTypes } from "helpers/enum_helper"
import Spinners from "components/Common/Spinner"
import { RESET_DOCTOR_SCHEDULE } from "store/scheduling/doctor/actionTypes"

const AssignModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [saveModal, setSaveModal] = useState(false)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ScheduleDate: yup.string().required("Required"),
    ScheduleStartTime: yup.string().required("Required"),
    ScheduleEndTime: yup.string().required("Required"),
    ScheduleTypeID: yup.string(),
    Children: yup.object().required("Required"),
    Doctors: yup.object().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ScheduleDate: "",
      ScheduleStartTime: "",
      ScheduleEndTime: "",
      ScheduleTypeID: 1,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetChildren())
      dispatch(onGetStaffs())
      dispatch({ type: RESET_DOCTOR_SCHEDULE })
    }

    updateState()
  }, [dispatch])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const { staffs } = useSelector(state => state.staff)
  //////////////////////////////////////

  //////////////////CHILDREN SCHEDULE STATE/////////////////////
  const { children } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////DOCTOR SCHEDULE STATE/////////////////////
  const { error, closeModal } = useSelector(state => state.doctorSchedules)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      setSaveModal(true)
      dispatch(onAddDoctorSchedule(getValues()))
    }
  }

  useEffect(() => {
    if (closeModal) {
      setSaveModal(false)
      onCloseClick()
    }
  }, [closeModal])

  const onClosed = () => {
    reset()
  }

  if (saveModal) {
    return (
      <Spinner color="primary" className="position-absolute top-50 start-50" />
    )
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Assign Televisit
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <AlertContainer message={error?.data} color="danger" />
            <Col lg={12}>
              <div className="mb-3">
                <Label>Schedule Date</Label>
                <Controller
                  name="ScheduleDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="ScheduleDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("ScheduleDate", dateStr)
                        }}
                        required
                      />
                      {errors?.ScheduleDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ScheduleDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Schedule Start Time</Label>
                <Controller
                  name="ScheduleStartTime"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="ScheduleStartTime"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            maxTime: getValues("ScheduleEndTime"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("ScheduleStartTime", dateStr)
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.ScheduleStartTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ScheduleStartTime?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Schedule End Time</Label>
                <Controller
                  name="ScheduleEndTime"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="ScheduleEndTime"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            minTime: getValues("ScheduleStartTime"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("ScheduleEndTime", dateStr)
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.ScheduleEndTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ScheduleEndTime?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Network Provider</Label>
                <Controller
                  name="Doctors"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Doctors"
                        options={staffs.filter(
                          x => x.RoleTypeID === EnumRoleTypes.Doctor
                        )}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.StaffID}
                        required
                        aria-invalid={!!errors.Doctors}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Doctors?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Doctors?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Child</Label>
                <Controller
                  name="Children"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Children"
                        options={children.filter(child => child.IsActive == 1)}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.ChildID}
                        required
                        aria-invalid={!!errors.Children}
                        className="select2-selection"
                      />
                      {errors?.Children?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Children?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

AssignModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default AssignModal
