import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Row, Label } from "reactstrap"

//react form
import { FormProvider, useForm } from "react-hook-form"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewStaff as onAddNewStaff,
  getRoleTypes as onGetRoleTypes,
  updateStaff as onUpdateStaff,
  updateStaffPhoto as onUpdateStaffPhoto,
  resetStaff as onResetStaff,
  getSpecializations as onGetSpecializations,
  getGenders as onGetGenders,
} from "store/actions"
import LocationField from "components/Common/Location/Location"
import PhotoField from "components/Common/Crop/PhotoField"
import Spinners from "components/Common/Spinner"
import { getUserImagePath } from "../../../helpers/imageService"
import { isEmpty } from "lodash"
import { EnumRoleTypes } from "helpers/enum_helper"

import {
  TextInput,
  DropdownInput,
  NumberInput,
  DateInput,
  ToggleInput,
} from "components/Common/Controls"
import AlertContainer from "components/Common/AlertContainer"

function BasicInfoTab({ staff, loading, myprofile = false }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    // LoginUserName: yup.string().required("Required"),
    IsActive: yup.bool(),
    Email: yup.string().required("Required").email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    DateOfBirth: yup
      .string()
      .test("DateOfBirth", "Should be above 18 Years", value => {
        return value !== "" ? Moment().diff(Moment(value), "years") >= 18 : true
      }),
    Address: yup.string(),
    RoleTypes: yup.object().required("Required"),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object(),
    ZipCode: yup.string().max(10, "Zipcode should be max 10 characters"),
    HireDate: yup.string(),
    SeparationDate: yup.string(),
    Specializations: yup.object().nullable(),
    Photo: yup
      .mixed()
      .nullable(true)
      .transform((_, val) => (val ? String(val) : null)),
    Genders: yup.object().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      LoginUserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      DateOfBirth: "",
      Address: "",
      // CountryID: "",
      // StateID: "",
      // CityID: "",
      ZipCode: "",
      HireDate: "",
      SeparationDate: "",
      Photo: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger, watch } =
    methods
  const { errors, isValid } = formState
  const RoleType = watch("RoleTypes")

  useDeepCompareEffect(() => {
    function updateStaffState() {
      dispatch(onGetRoleTypes())
      dispatch(onGetSpecializations())
      dispatch(onGetGenders())

      //reset form with server data
      reset(staff)
    }

    updateStaffState()
  }, [dispatch])

  //////////////////ROLE STATE/////////////////////
  const { roles, roletypes } = useSelector(state => state.role)
  ///////////////////////////////////////

  //////////////////HELPER STATE/////////////////////
  const { specializations } = useSelector(state => state.helper)
  ///////////////////////////////////////

  //////////////////STAFF STATE/////////////////////
  const { genders, error } = useSelector(state => state.staff)
  ///////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetStaff())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/staffs")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      if (isEmpty(staff)) {
        dispatch(onAddNewStaff(getValues()))
      } else {
        dispatch(onUpdateStaffPhoto(getValues()))
        dispatch(onUpdateStaff(getValues()))
      }
      if (!isEmpty(id) && isEmpty(error)) {
        navigate("/staffs")
      }
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="4" className="order-md-3">
          <div className="mb-3">
            <PhotoField
              url={getUserImagePath(staff?.RowID, staff?.Photo ?? "")}
            />
          </div>
        </Col>
        <Col sm="4">
          <div hidden={isEmpty(staff)} className="mb-3">
            <Label>Username</Label>
            <div>{getValues("LoginUserName")}</div>
          </div>
          <div className="mb-3">
            <TextInput label="First Name" id="FirstName" required={true} />
          </div>
          <div className="mb-3">
            <TextInput label="Last Name" id="LastName" required={true} />
          </div>
          <div className="mb-3">
            <DropdownInput
              label="Gender"
              id="Genders"
              required={true}
              items={genders}
              itemlabel="Gender"
              itemvalue="GenderID"
            />
          </div>
          <div className="mb-3">
            <DropdownInput
              label="Role"
              id="RoleTypes"
              required={true}
              items={roletypes.filter(x => x.IsSystem === false)}
              itemlabel="RoleType"
              itemvalue="RoleTypeID"
            />
          </div>
          {RoleType?.RoleTypeID === EnumRoleTypes.Doctor ? (
            <div className="mb-3">
              <DropdownInput
                label="Specialization"
                id="Specializations"
                items={specializations}
                itemlabel="Specialization"
                itemvalue="SpecializationID"
              />
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <TextInput label="Email" id="Email" required={true} />
          </div>
          <div className="mb-3">
            <NumberInput
              label="Phone Number"
              id="PhoneNumber"
              required={true}
            />
          </div>
          <div className="mb-3">
            <DateInput label="Date of Birth" id="DateOfBirth" required={true} />
          </div>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <DateInput
                  label="Hire Date"
                  id="HireDate"
                  required={true}
                  maxDate="SeparationDate"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <DateInput
                  label="Separation Date"
                  id="SeparationDate"
                  required={true}
                  minDate="HireDate"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <LocationField />
        </Col>
        <div className="mb-3">
          <ToggleInput label="Active" id="IsActive" />
        </div>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {isEmpty(staff) ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
          hidden={myprofile}
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
