import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Class Redux States
import {
  GET_DOCUMENTS,
  ADD_NEW_DOCUMENT,
  DELETE_DOCUMENT,
  GET_PRIVACY_TYPES,
} from "./actionTypes"
import {
  getDocumentsSuccess,
  getDocumentsFail,
  addDocumentSuccess,
  addDocumentFail,
  deleteDocumentSuccess,
  deleteDocumentFail,
  getPrivacyTypesSuccess,
  getPrivacyTypesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  deleteDocument,
  getDocuments,
  addNewDocument,
  getPrivacyTypes,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchDocuments({ filter }) {
  try {
    const response = yield call(getDocuments, filter)

    let result = response.map(x => ({
      ...x,
      PrivacyTypes: {
        PrivacyTypeID: x.PrivacyTypeID ?? 0,
        PrivacyType: x.PrivacyType ?? "",
      },
      ExpiryDate: x.ExpiryDate
        ? Moment(x.ExpiryDate).format("DD MMM, YYYY")
        : "",
      Children: {
        ChildID: x.ChildID,
        Name: x.ChildName,
      },
    }))

    yield put(getDocumentsSuccess(result))
  } catch (error) {
    yield put(getDocumentsFail(error))
  }
}

function* onDeleteDocument({ payload: document }) {
  try {
    const response = yield call(deleteDocument, document)
    yield put(deleteDocumentSuccess(response))
    toast.success("Document deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteDocumentFail(error))
    toast.error("Document deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewDocument({ payload: document }) {
  try {
    var formData = new FormData()
    if (document.Attachment !== undefined) {
      formData.append("Attachment", document.Attachment)
    }
    formData.append("Data", JSON.stringify(document))
    // documentData.append("objectArtifactID", document.ObjectArtifactID)
    // documentData.append("files", document.FileName)
    // documentData.append("refId", document.ObjectID)
    // documentData.append("expiryDate", document.ExpiryDate)
    // documentData.append("isActive", document.IsActive)
    // documentData.append(
    //   "privacyTypeID",
    //   document.PrivacyTypes.PrivacyTypeID ?? 0
    // )
    // documentData.append("shareToChildren", document.ShareToChildren ?? false)
    // documentData.append("shareToStaff", document.ShareToStaff ?? false)
    // documentData.append("description", document.Description ?? "")
    // documentData.append("field", document.ObjectTypeID)
    // documentData.append("childID", document.Children?.ChildID ?? 0)
    const response = yield call(addNewDocument, formData)

    let result = {
      ...response,
      PrivacyTypes: {
        PrivacyTypeID: response.PrivacyTypeID ?? 0,
        PrivacyType: response.PrivacyType ?? "",
      },
      ExpiryDate: response.ExpiryDate
        ? Moment(response.ExpiryDate).format("DD MMM, YYYY")
        : "",
    }

    yield put(addDocumentSuccess(result))
    toast.success("Document added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addDocumentFail(error))
    toast.error("Document addition failed", { autoClose: 2000 })
  }
}

function* fetchPrivacyTypes({ payload: objectTypeID }) {
  try {
    const response = yield call(getPrivacyTypes, objectTypeID)
    yield put(getPrivacyTypesSuccess(response))
  } catch (error) {
    yield put(getPrivacyTypesFail(error))
  }
}

function* documentSaga() {
  yield takeEvery(GET_DOCUMENTS, fetchDocuments)
  yield takeEvery(DELETE_DOCUMENT, onDeleteDocument)
  yield takeEvery(ADD_NEW_DOCUMENT, onAddNewDocument)
  yield takeEvery(GET_PRIVACY_TYPES, fetchPrivacyTypes)
}

export default documentSaga
