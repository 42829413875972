import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import classnames from "classnames"
import LiveStatusTab from "./tabs/LiveStatusTab"
import ScheduledTab from "./tabs/ScheduledTab"
import AwaitingReleaseTab from "./tabs/AwaitingReleaseTab"
import WaitlistTab from "./tabs/WaitlistTab"

const CenterStatus = props => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState("1")

  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////

  useEffect(() => {}, [dispatch])

  //meta title
  document.title =
    "Status | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Status")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {NavItemTab("Live Status", "1")}
                    {NavItemTab("Scheduled", "2")}
                    {NavItemTab("Awaiting Release", "3")}
                    {NavItemTab("Waitlist", "4")}
                  </Nav>
                  <TabContent activeTab={tabValue} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <LiveStatusTab />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <ScheduledTab />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <AwaitingReleaseTab />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <WaitlistTab />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CenterStatus.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CenterStatus)
