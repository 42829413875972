import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import BasicInfoTab from "./tabs/BasicInfoTab"
import MediaAlbumTab from "./tabs/MediaAlbumTab"

import { getAlbumDetail as onGetAlbumDetail } from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"

const AlbumAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title ="Albums | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Album")
    } else {
      setHeader("Edit Album")
      dispatch(onGetAlbumDetail(id))
    }
  }, [dispatch, routeParams])

  //////////////////Album STATE/////////////////////
  const data = useSelector(state => state.album)
  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }
  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Album" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.album) ? (
            <EmptyContainer
              backURL="/albums"
              message="There is no such album!"
              linkText="Go to Albums Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <Row>
                      <Col sm="12">
                        <BasicInfoTab {...data} />
                      </Col>
                    </Row> */}

                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    {routeParams.id !== "new" ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Album", "2")}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab {...data} />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                          <  MediaAlbumTab {...data} />
                        
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AlbumAddEdit
