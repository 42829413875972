import {
  GET_CHILDREN_FAIL,
  GET_CHILDREN_SUCCESS,
  DELETE_CHILD_SUCCESS,
  DELETE_CHILD_FAIL,
  ADD_CHILD_FAIL,
  ADD_CHILD_SUCCESS,
  GET_CHILD_DETAIL_SUCCESS,
  GET_CHILD_DETAIL_FAIL,
  UPDATE_CHILD_SUCCESS,
  UPDATE_CHILD_FAIL,
  RESET_CHILD_SUCCESS,
  UPDATE_CHILD_PHOTO_SUCCESS,
  UPDATE_CHILD_PHOTO_FAIL,
  ADD_CHILD_PROVIDED_FOOD_SUCCESS,
  ADD_CHILD_PROVIDED_FOOD_FAIL,
  GET_CHILD_PROVIDED_FOOD_SUCCESS,
  GET_CHILD_PROVIDED_FOOD_FAIL,
  ADD_CHILD_NOTES_SUCCESS,
  ADD_CHILD_NOTES_FAIL,
  GET_CHILD_NOTES_SUCCESS,
  GET_CHILD_NOTES_FAIL,
  GET_CHILD_AVAILABILITY_SUCCESS,
  GET_CHILD_AVAILABILITY_FAIL,
  ADD_CHILD_AVAILABILITY_SUCCESS,
  ADD_CHILD_AVAILABILITY_FAIL,
  GET_CHILD_RELATIONS_SUCCESS,
  GET_CHILD_RELATIONS_FAIL,
  GET_RELATION_TYPES_SUCCESS,
  GET_RELATION_TYPES_FAIL,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_PERMISSION_TYPES_FAIL,
  ADD_CHILD_RELATION_SUCCESS,
  ADD_CHILD_RELATION_FAIL,
  DELETE_CHILD_RELATION_SUCCESS,
  DELETE_CHILD_RELATION_FAIL,
  GET_HAPPENING_TYPES_SUCCESS,
  GET_HAPPENING_TYPES_FAIL,
  GET_CHILD_HAPPENING_SUCCESS,
  GET_CHILD_HAPPENING_FAIL,
  ADD_CHILD_HAPPENING_SUCCESS,
  ADD_CHILD_HAPPENING_FAIL,
  GET_CHILD_HAPPENING_DETAIL_SUCCESS,
  GET_CHILD_HAPPENING_DETAIL_FAIL,
  UPDATE_CHILD_HAPPENING_SUCCESS,
  UPDATE_CHILD_HAPPENING_FAIL,
  DELETE_CHILD_HAPPENING_SUCCESS,
  DELETE_CHILD_HAPPENING_FAIL,
  GET_HOUR_TYPES_SUCCESS,
  GET_HOUR_TYPES_FAIL,
  GET_TAG_CATEGORY_SUCCESS,
  GET_TAG_CATEGORY_FAIL,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGE_POSTS_SUCCESS,
  GET_MESSAGE_POSTS_FAIL,
  ADD_NEW_MESSAGE_SUCCESS,
  ADD_NEW_MESSAGE_FAIL,
  ADD_MESSAGE_POST_SUCCESS,
  ADD_MESSAGE_POST_FAIL,
  GET_AWAITED_MESSAGES_SUCCESS,
  GET_AWAITED_MESSAGES_FAIL,
  UPDATE_MESSAGE_APPROVAL_SUCCESS,
  UPDATE_MESSAGE_APPROVAL_FAIL,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_POST_SUCCESS,
  DELETE_MESSAGE_POST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  children: [],
  child: {},
  providedfoods: [],
  notes: [],
  availabilities: [],
  availabilitiesloading: true,
  relations: [],
  relationtypes: [],
  permissiontypes: [],
  error: {},
  loading: true,
  happeningTypes: [],
  childHappenings: [],
  childHappening: {},
  hourTypes: [],
  tags: [],
  tagcategory: [],
  messages: [],
  messageposts: [],
  awaited: [],
}

const Child = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHILDREN_SUCCESS:
      return {
        ...state,
        children: action.payload,
        loading: true,
      }
    case GET_CHILDREN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CHILD_SUCCESS: {
      return {
        ...state,
        children: state.children.filter(
          child => child.ChildID !== action.payload
        ),
      }
    }
    case DELETE_CHILD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_SUCCESS:
      return {
        ...state,
        children: [...state.children, action.payload],
      }
    case ADD_CHILD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_DETAIL_SUCCESS:
      return {
        ...state,
        child: action.payload,
        loading: true,
      }

    case GET_CHILD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHILD_SUCCESS:
      return {
        ...state,
        children: state.children.map(child =>
          child.ChildID.toString() === action.payload.ChildID.toString()
            ? { child, ...action.payload }
            : child
        ),
      }

    case UPDATE_CHILD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_CHILD_SUCCESS:
      return {
        ...state,
        child: action.payload,
      }

    case UPDATE_CHILD_PHOTO_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_CHILD_PHOTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_PROVIDED_FOOD_SUCCESS:
      return {
        ...state,
        providedfoods: action.payload,
      }
    case ADD_CHILD_PROVIDED_FOOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_PROVIDED_FOOD_SUCCESS:
      return {
        ...state,
        providedfoods: action.payload,
      }
    case GET_CHILD_PROVIDED_FOOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      }
    case ADD_CHILD_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      }
    case GET_CHILD_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availabilities: action.payload,
        availabilitiesloading: true,
      }
    case GET_CHILD_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availabilities: action.payload,
      }
    case ADD_CHILD_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_RELATIONS_SUCCESS:
      return {
        ...state,
        relations: action.payload,
      }
    case GET_CHILD_RELATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_RELATION_TYPES_SUCCESS:
      return {
        ...state,
        relationtypes: action.payload,
      }
    case GET_RELATION_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PERMISSION_TYPES_SUCCESS:
      return {
        ...state,
        permissiontypes: action.payload,
      }
    case GET_PERMISSION_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_RELATION_SUCCESS:
      return {
        ...state,
        relations: action.payload,
      }
    case ADD_CHILD_RELATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CHILD_RELATION_SUCCESS: {
      return {
        ...state,
        relations: state.relations.filter(
          c => c.ChildRelationID !== action.payload
        ),
      }
    }
    case DELETE_CHILD_RELATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_HAPPENING_TYPES_SUCCESS:
      return {
        ...state,
        happeningTypes: action.payload,
        loading: true,
      }
    case GET_HAPPENING_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_HAPPENING_SUCCESS:
      return {
        ...state,
        childHappenings: action.payload,
        loading: true,
      }
    case GET_CHILD_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CHILD_HAPPENING_SUCCESS:
      return {
        ...state,
        childHappenings: action.payload.ChildHappenings,
        children: state.children.map(c =>
          c.ChildID.toString() === action.payload.ChildID.toString()
            ? { c, ...action.payload }
            : c
        ),
      }
    case ADD_CHILD_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_HAPPENING_DETAIL_SUCCESS:
      return {
        ...state,
        childHappening: action.payload,
        loading: true,
      }

    case GET_CHILD_HAPPENING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHILD_HAPPENING_SUCCESS:
      return {
        ...state,
        childHappenings: action.payload.ChildHappenings,
        children: state.children.map(c =>
          c.ChildID.toString() === action.payload.ChildID.toString()
            ? { c, ...action.payload }
            : c
        ),
      }
    case UPDATE_CHILD_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CHILD_HAPPENING_SUCCESS: {
      return {
        ...state,
        childHappenings: state.childHappenings.filter(
          childHappening => childHappening.ChildHappeningID !== action.payload
        ),
      }
    }
    case DELETE_CHILD_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // case GET_HOUR_TYPES_SUCCESS:
    //   return {
    //     ...state,
    //     hourTypes: action.payload,
    //     loading: true,
    //   }
    // case GET_HOUR_TYPES_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        loading: true,
      }
    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TAG_CATEGORY_SUCCESS:
      return {
        ...state,
        tagcategory: action.payload,
      }
    case GET_TAG_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_TAG_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      }
    case ADD_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_TAG_SUCCESS: {
      return {
        ...state,
        tags: state.tags.filter(x => x.TagID !== action.payload),
      }
    }
    case DELETE_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        loading: true,
      }
    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MESSAGE_POSTS_SUCCESS:
      return {
        ...state,
        messageposts: action.payload,
        loading: true,
      }
    case GET_MESSAGE_POSTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        loading: true,
      }
    case ADD_NEW_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MESSAGE_POST_SUCCESS:
      return {
        ...state,
        messageposts: action.payload,
        loading: true,
      }
    case ADD_MESSAGE_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_AWAITED_MESSAGES_SUCCESS:
      return {
        ...state,
        awaited: action.payload,
        loading: true,
      }
    case GET_AWAITED_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_MESSAGE_APPROVAL_SUCCESS:
      return {
        ...state,
        awaited: action.payload,
        loading: true,
      }
    case UPDATE_MESSAGE_APPROVAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: state.messages.filter(x => x.MessageID !== action.payload),
      }
    }
    case DELETE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MESSAGE_POST_SUCCESS: {
      return {
        ...state,
        messageposts: state.messageposts.filter(
          x => x.MessagePostID !== action.payload
        ),
      }
    }
    case DELETE_MESSAGE_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Child
