import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import { isEmpty } from "lodash"

import {
  getAdults as onGetAdults,
  getRelationTypes as onGetRelationTypes,
  getPermissionTypes as onGetPermissionTypes,
  addRelation as onAddRelation,
} from "store/actions"
import PageModal from "components/Common/PageModal"

const formdefault = {
  ChildRelationID: 0,
  OtherRelation: "",
  Adults: "",
  RelationTypes: "",
  AdultPermissions: [],
}

const RelationModal = ({ show, data, onCloseClick }) => {
  const dispatch = useDispatch()
  const [types, setTypes] = useState([])

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ChildRelationID: yup.number(),
    Adults: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    RelationTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    OtherRelation: yup
      .string()
      .when("RelationTypes", {
        is: value => value?.RelationTypeID === 8,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    AdultPermissions: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState
  const RelationType = watch("RelationTypes")

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetAdults())
      dispatch(onGetRelationTypes())
      dispatch(onGetPermissionTypes())
    }

    updateState()
  }, [dispatch])

  //////////////////ADULT SCHEDULE STATE/////////////////////
  const { adults } = useSelector(state => state.adult)
  //////////////////////////////////////

  //////////////////CHILD SCHEDULE STATE/////////////////////
  const { child, relationtypes, permissiontypes } = useSelector(
    state => state.child
  )
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddRelation(child.ChildID, getValues()))
      onCloseClick()
    }
  }

  const onPermissionSelect = e => {
    const { value, checked } = e.target

    const existing = [...types]
    if (checked) {
      existing.push(value)
      setTypes(existing)
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      setTypes(updatedValue)
    }
  }

  useEffect(() => {
    setValue("AdultPermissions", types)
  }, [types])

  useEffect(() => {
    if (isEmpty(data)) {
      reset(formdefault)
      setTypes([])
    } else {
      const result = {
        ...data,
        Adults: { AdultID: data.AdultID, Name: data.Name },
        RelationTypes: {
          RelationTypeID: data.RelationTypeID,
          Relation: data.Relation,
        },
        OtherRelation: data.OtherRelation ?? "",
        LinkedPermissions: data.LinkedPermissions ?? [],
      }

      setTypes(
        result.LinkedPermissions.length > 0
          ? result.LinkedPermissions.split(",")
          : []
      )
      reset(result)
    }
  }, [data])

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Add Family Relation"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Parent to relate</Label>
              <Controller
                name="Parents"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="Adults"
                      options={adults}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.AdultID}
                      required
                      aria-invalid={!!errors.Adults}
                      className="select2-selection"
                    />
                    {errors?.Adults?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Adults?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Relation to child</Label>
              <Controller
                name="RelationTypes"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="RelationTypes"
                      options={relationtypes}
                      getOptionLabel={option => option.Relation}
                      getOptionValue={option => option.RelationTypeID}
                      required
                      aria-invalid={!!errors.RelationTypes}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.RelationTypes?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.RelationTypes?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            {RelationType.RelationTypeID === 8 ? (
              <div className="mb-3">
                <Label>Other Relation</Label>
                <Controller
                  name="OtherRelation"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="OtherRelation"
                        type="text"
                        required
                        invalid={!!errors.OtherRelation}
                      />
                      {errors?.OtherRelation?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.OtherRelation?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            ) : (
              ""
            )}
            <div className="mb-3">
              <Label>Permissions</Label>
              {(permissiontypes || []).map((type, i) => (
                <div
                  className="form-check form-check-primary d-flex align-items-center mb-3"
                  key={"d" + i}
                >
                  <input
                    type="checkbox"
                    className="form-check-input checkbox-medium me-2"
                    value={type.AdultPemissionTypeID}
                    defaultChecked={
                      data.LinkedPermissions &&
                      data.LinkedPermissions.indexOf(
                        type.AdultPemissionTypeID
                      ) != -1
                    }
                    id={"dvCheck" + i}
                    onChange={onPermissionSelect}
                    autoComplete="off"
                  />
                  <label className="form-check-label" htmlFor={"dvCheck" + i}>
                    {type.PemissionType}
                    <div className="small">{type.PemissionDescription}</div>
                  </label>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

RelationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default RelationModal
