import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"

import BasicInfoTab from "./tabs/BasicInfoTab"
import { getRoomDetail as onGetRoomDetail } from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"

const RoomAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title = "Rooms | " +  ( basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Room")
    } else {
      setHeader("Edit Room")
      dispatch(onGetRoomDetail(id))
    }
  }, [dispatch, routeParams])

  //////////////////Room STATE/////////////////////
  const data = useSelector(state => state.room)
  //////////////////////////////////////

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Room" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.room) ? (
            <EmptyContainer
              backURL="/rooms"
              message="There is no such room!"
              linkText="Go to Rooms Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <Row>
                      <Col sm="12">
                        <BasicInfoTab {...data} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RoomAddEdit
