import React, { useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import { getAdultImagePath, getUserImagePath } from "helpers/imageService"

//redux
import { useSelector, useDispatch } from "react-redux"

import profileImg from "../../assets/images/profile-img.png"
import { useUserDetail } from "hooks"

//import action
import { addLMSProfile as onAddLMSProfile } from "../../store/actions"
import { isEmpty } from "lodash"

const WelcomeComp = () => {
  const dispatch = useDispatch()
  const { userprofile } = useUserDetail()

  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////

  //////////////////USER STATE/////////////////////
  const { lmsRowID } = useSelector(state => state.user)
  //////////////////////////////////////

  const GotoLMS = () => {
    debugger
    if (!isEmpty(userprofile?.LMSRowID)) {
      window.open(
        `${basicSetting?.LMSUrl}?guid=${userprofile?.LMSRowID}`,
        "_blank"
      )
    } else {
      dispatch(onAddLMSProfile(userprofile?.LoginID))
    }
  }

  useEffect(() => {
    if (!isEmpty(lmsRowID)) {
      window.open(`${basicSetting?.LMSUrl}?guid=${lmsRowID}`, "_blank")
    }
  }, [lmsRowID])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Childcare Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="5">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  alt={userprofile.Name}
                  src={
                    userprofile.RoleID === 1 || userprofile.RoleID === 2
                      ? getUserImagePath(userprofile.RowID, "image.jpeg")
                      : getAdultImagePath(userprofile.RowID, "image.jpeg")
                  }
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15">{userprofile?.Name}</h5>
              <p className="text-muted mb-0">{userprofile?.RoleType}</p>
            </Col>

            <Col sm="7">
              <div className="pt-4">
                {/* <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Unpaid</p>
                  </Col>
                </Row> */}
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>{" "}
                  <Link
                    onClick={() => GotoLMS()}
                    className="btn btn-primary  btn-sm"
                  >
                    Go to LMS <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
