import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getSubscriptions as onGetSubscriptions,
  deleteSubscription as onDeleteSubscription,
  getBasicSettings as onGetBasicSettings,
} from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"
import SubscriptionModal from "./modal/SubscriptionModal"
//custom hooks
import { useDeepCompareEffect } from "hooks"
function Subscription() {
  const dispatch = useDispatch()

  const { subscriptions, loading } = useSelector(state => state.subscription)

  const [isLoading, setLoading] = useState(loading)
  const [addModal, setAddModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const { basicSetting } = useSelector(state => state.Layout)

  //meta title
  document.title =
    "Subscriptions | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useDeepCompareEffect(() => {
    dispatch(onGetBasicSettings())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetBasicSettings())
    dispatch(onGetSubscriptions())
  }, [dispatch, basicSetting])

  const handleAddClick = () => {
    setEdit(false)
    setAddModal(true)
  }

  const onClickEdit = arg => {
    // dispatch(onGetSubscriptionDetail(arg.RowID))
    setEdit(true)
    setAddModal(true)
  }

  const onClickDelete = arg => {
    setSubscription(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (subscription && subscription.SubscriptionID) {
      dispatch(onDeleteSubscription(subscription.SubscriptionID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const onCloseModal = () => {
    setAddModal(false)
  }

  const SubscriptionStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "SubscriptionType",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.value}</h5>
            </>
          )
        },
      },
      {
        Header: "Start Date",
        accessor: "StartDate",
        Cell: cellProps => {
          return (
            <>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.StartDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "End Date",
        accessor: "EndDate",
        Cell: cellProps => {
          return (
            <>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.EndDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <SubscriptionStatus status={cellProps.value} />
        },
      },
      // {
      //   Header: "Action",
      //   Cell: cellProps => {
      //     return (
      //       <div className="d-flex gap-3">
      //         <Link
      //           to="#"
      //           onClick={() => {
      //             onClickEdit(cellProps.row.original)
      //           }}
      //           className="text-secondary"
      //         >
      //           <i className="bx bx-pencil font-size-18" id="edittooltip" />
      //           <UncontrolledTooltip placement="top" target="edittooltip">
      //             Edit
      //           </UncontrolledTooltip>
      //         </Link>
      //         <Link
      //           to="#"
      //           className="text-danger"
      //           onClick={() => {
      //             onClickDelete(cellProps.row.original)
      //           }}
      //         >
      //           <i className="bx bx-trash font-size-18" id="deletetooltip" />
      //           <UncontrolledTooltip placement="top" target="deletetooltip">
      //             Delete
      //           </UncontrolledTooltip>
      //         </Link>
      //       </div>
      //     )
      //   },
      // },
    ],
    []
  )

  return (
    <React.Fragment>
      {addModal && (
        <SubscriptionModal
          show={addModal}
          onEdit={edit}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Subscriptions"
            breadcrumbItem="Manage Subscriptions"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={subscriptions}
                      isAddOption={true}
                      addOptionText="Add"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded mb-0 ms-auto"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Subscription.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Subscription
