import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"
import { useDeepCompareEffect } from "hooks"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAlbums as onGetAlbums,
  deleteAlbum as onDeleteAlbum,
} from "store/actions"

import { getAdultVisibility, getStaffVisibility } from "helpers/token_helper"
import { getAuthUser } from "helpers/token_helper"
import { EnumRoleTypes } from "helpers/enum_helper"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
//import { ToastContainer } from "react-toastify"

function Albums() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { albums, loading } = useSelector(state => state.album)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [album, setAlbum] = useState(null)
  const [editable, setEditable] = useState(true)
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title ="Albus | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useEffect(() => {
    if (albums && !albums.length) {
      dispatch(onGetAlbums())
    }
  }, [dispatch, albums])

  useDeepCompareEffect(() => {
    if (
      getAdultVisibility() ||
      getStaffVisibility([
        EnumRoleTypes.Teaching_Staff,
        EnumRoleTypes.Non_Teaching_Staff,
        EnumRoleTypes.Accountant,
      ])
    ) {
      setEditable(false)
    }
  }, [dispatch])

  const handleAddClick = () => {
    navigate("/album/new")
  }

  const onClickDelete = arg => {
    setAlbum(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (album && album.AlbumID) {
      dispatch(onDeleteAlbum(album.AlbumID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const AlbumStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Title",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <AlbumStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {editable ? (
                <>
                  <Link
                    to={"/album/" + cellProps.row.original.RowID}
                    className="text-secondary"
                  >
                    <i className="bx bx-pencil font-size-18" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      onClickDelete(cellProps.row.original)
                    }}
                  >
                    <i
                      className="bx bx-trash font-size-18"
                      id="deletetooltip"
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </>
              ) : (
                <Link
                  to={"/album-detail/" + cellProps.row.original.RowID}
                  className="btn btn-primary btn-sm"
                >
                  View
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    [editable]
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Albums" breadcrumbItem="Album List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={
                        editable
                          ? albums // Show all albums when editable
                          : albums.filter(album => album.IsActive == 1) // Show only active albums when not editable
                      }
                      isGlobalFilter={true}
                      isAddOption={editable ? true : false}
                      addOptionText="Add album"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded mb-0 ms-auto"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Albums.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Albums
