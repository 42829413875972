/**
 * Add EXTERNAL PARENT
 */
export const ADD_EXTERNAL_PARENT = "ADD_EXTERNAL_PARENT"
export const ADD_EXTERNAL_PARENT_SUCCESS = "ADD_EXTERNAL_PARENT_SUCCESS"
export const ADD_EXTERNAL_PARENT_FAIL = "ADD_EXTERNAL_PARENT_FAIL"

/**
 * Update VERIFY EXTERNAL PARENT
 */
export const VERIFY_EXTERNAL_PARENT = "VERIFY_EXTERNAL_PARENT"
export const VERIFY_EXTERNAL_PARENT_SUCCESS = "VERIFY_EXTERNAL_PARENT_SUCCESS"
export const VERIFY_EXTERNAL_PARENT_FAIL = "VERIFY_EXTERNAL_PARENT_FAIL"
