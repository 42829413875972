import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "components/Common/TableContainer"

//import components
import DeleteModal from "components/Common/DeleteModal"
import { useDeepCompareEffect } from "hooks"
import {
  getDocuments as onGetDocuments,
  deleteDocument as onDeleteDocument,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import DocumentModal from "./Modal/DocumentModal"
import { downloadArtifactDocument } from "helpers/backend_helper"

function Document(props) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    objectID: props.objectID ?? 0,
    objectTypeID: props.objectTypeID,
  })
  const { documents, loading } = useSelector(state => state.document)
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  //document.title ="Documents | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  const [documentData, setDocument] = useState(null)
  useDeepCompareEffect(() => {
    const { id } = routeParams
    if (!id) {
      //meta title
      document.title =
        "Documents | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
    }
  }, [dispatch, basicSetting])
  useEffect(() => {
    dispatch(onGetDocuments(filter))
  }, [dispatch])

  const handleAddClick = () => {
    setDocumentModal(true)
  }

  const handleEditClick = arg => {
    setDocument(arg)
    setDocumentModal(true)
  }

  const onClickDelete = arg => {
    setDocument(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (documentData && documentData.ObjectArtifactID) {
      dispatch(onDeleteDocument(documentData.ObjectArtifactID))
      setDeleteModal(false)
    }
  }

  const onCloseModal = () => {
    setDocument(null)
    setDocumentModal(false)
  }

  const downloadFile = async obj => {
    try {
      const response = await downloadArtifactDocument(obj.RowID)

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })

      // Create a link element, set its href to the blob URL, and trigger a download
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = obj.FileName
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      document.body.removeChild(link)
    } catch (error) {
      console.error("Error downloading the file", error)
    }
  }

  const DocumentStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "File Name",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to="#"
                  onClick={() => {
                    downloadFile(cellProps.row.original)
                  }}
                >
                  {cellProps.row.original.FileName}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <DocumentStatus status={cellProps.row.original.IsActive} />
              </p>
            </>
          )
        },
      },
      {
        Header: "Format",
        accessor: "Type",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Expiration Date",
        accessor: "ExpiryDate",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : "Never"
        },
      },
      {
        Header: "Uploaded",
        accessor: "CreatedDate",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {Moment(cellProps.row.original.CreatedDate).format(
                    "DD MMM yyyy"
                  )}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                {cellProps.row.original.UploadedBy}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                onClick={() => {
                  handleEditClick(cellProps.row.original)
                }}
                className="text-secondary"
              >
                <i className="bx bx-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {documentModal && (
        <DocumentModal
          show={documentModal}
          onCloseClick={() => onCloseModal()}
          objectTypeID={props.objectTypeID}
          objectID={props.objectID}
          data={documentData}
        />
      )}

      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <TableContainer
          columns={columns}
          data={documents}
          isAddOption={true}
          addOptionText="Upload document"
          handleAddClick={handleAddClick}
          customPageSize={10}
          isPagination={true}
          tableClass="align-middle table-nowrap table-check"
          theadClass="table-light"
          pagination="pagination pagination-rounded mb-0 ms-auto"
        />
      )}
    </>
  )
}

export default Document
