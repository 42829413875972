import React, { useState, useEffect } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
//redux
import { useSelector } from "react-redux"
// img
import authOverlay from "../../assets/images/auth/1.png"

const CarouselPage = () => {
  const { basicSetting, loading } = useSelector(state => state.Layout)
  const [authOverlayURL, setAuthOverlayURL] = useState(authOverlay)

  useEffect(() => {
    if (basicSetting?.BannerFileName) {
      const bannerURL = `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting.BannerFileName}`
      setAuthOverlayURL(bannerURL)
    } else {
      setAuthOverlayURL(authOverlay) // Default fallback
    }
  }, [loading, basicSetting])

  return (
    <React.Fragment>
      <Col xl={9} className="d-none d-md-block">
        <div className="auth-full-bg">
          <div className="w-100">
            <div
              className=""
              style={{
                backgroundImage: `url(${authOverlayURL || authOverlay})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                opacity: "1",
                height:
                  window.innerWidth >= 1200
                    ? "100%"
                    : window.innerWidth >= 768
                    ? "300px"
                    : "200px",
                position: "relative",
              }}
            ></div>
            <div className="d-none h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">1k</span>+ Satisfied
                        clients
                      </h4>
                      <div dir="ltr">
                        <Carousel
                          className="owl-carousel owl-theme auth-review-carousel slider_css"
                          id="auth-review-carousel"
                          showThumbs={false}
                        >
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Royal Cardinal has worked amazingly so
                                  far within our Nursery. The system has great
                                  features and links directly linked to The
                                  Early Years Foundation Stage and Early
                                  learning goals. It is great for Ofsted
                                  evidence and great for keeping notes for
                                  parents on child development. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Christopher
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    -{" "}
                                    {basicSetting?.SiteName ||
                                      process.env.REACT_APP_NAME}{" "}
                                    User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Royal cardinal is a very reliable and
                                  easy childcare software management system. I
                                  have been using this for my setting for 2
                                  years and Ive never had any problems.&ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Pravin
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    -{" "}
                                    {basicSetting?.SiteName ||
                                      process.env.REACT_APP_NAME}{" "}
                                    User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
