import React from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"

import FamilyProfile from "./FamilyProfile"

function FamilyPage() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Family" breadcrumbItem="Family Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <FamilyProfile />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyPage
