import {
  ADD_EXTERNAL_PARENT_SUCCESS,
  ADD_EXTERNAL_PARENT_FAIL,
  VERIFY_EXTERNAL_PARENT_SUCCESS,
  VERIFY_EXTERNAL_PARENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  success: false,
  parent: {},
  error: {},
  loading: false,
}

const External = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EXTERNAL_PARENT_SUCCESS:
      return {
        ...state,
        loading: true,
        success: true,
      }
    case ADD_EXTERNAL_PARENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case VERIFY_EXTERNAL_PARENT_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        loading: true,
      }
    case VERIFY_EXTERNAL_PARENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default External
