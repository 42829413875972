import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION_SUCCESS,
  ADD_NEW_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_DETAIL,
  GET_SUBSCRIPTION_DETAIL_SUCCESS,
  GET_SUBSCRIPTION_DETAIL_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  RESET_SUBSCRIPTION_SUCCESS,
  RESET_SUBSCRIPTION,
  GET_SUBSCRIPTION_TYPES,
  GET_SUBSCRIPTION_TYPES_FAIL,
  GET_SUBSCRIPTION_TYPES_SUCCESS,
} from "./actionTypes"

export const getSubscriptions = () => ({
  type: GET_SUBSCRIPTIONS,
})

export const getSubscriptionsSuccess = meals => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: meals,
})

export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
})

export const deleteSubscription = meal => ({
  type: DELETE_SUBSCRIPTION,
  payload: meal,
})

export const deleteSubscriptionSuccess = meal => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload: meal,
})

export const deleteSubscriptionFail = error => ({
  type: DELETE_SUBSCRIPTION_FAIL,
  payload: error,
})
export const addNewSubscription = meal => ({
  type: ADD_NEW_SUBSCRIPTION,
  payload: meal,
})

export const addSubscriptionSuccess = meal => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  payload: meal,
})

export const addSubscriptionFail = error => ({
  type: ADD_SUBSCRIPTION_FAIL,
  payload: error,
})

export const getSubscriptionDetail = mealId => ({
  type: GET_SUBSCRIPTION_DETAIL,
  mealId,
})

export const getSubscriptionDetailSuccess = meal => ({
  type: GET_SUBSCRIPTION_DETAIL_SUCCESS,
  payload: meal,
})

export const getSubscriptionDetailFail = error => ({
  type: GET_SUBSCRIPTION_DETAIL_FAIL,
  payload: error,
})

export const updateSubscription = meal => ({
  type: UPDATE_SUBSCRIPTION,
  payload: meal,
})

export const updateSubscriptionSuccess = meal => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: meal,
})

export const updateSubscriptionFail = error => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
})
export const resetSubscription = () => ({
  type: RESET_SUBSCRIPTION,
})

export const resetSubscriptionSuccess = meal => ({
  type: RESET_SUBSCRIPTION_SUCCESS,
  payload: meal,
})


export const getSubscriptionTypes = () => ({
  type: GET_SUBSCRIPTION_TYPES,
})

export const getSubscriptionTypesSuccess = meals => ({
  type: GET_SUBSCRIPTION_TYPES_SUCCESS,
  payload: meals,
})

export const getSubscriptionTypesFail = error => ({
  type: GET_SUBSCRIPTION_TYPES_FAIL,
  payload: error,
})
