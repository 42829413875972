import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import DeleteModal from "../../../../components/Common/DeleteModal"
import {
  getAllNotes as onGetAllNote,
  deleteNote as onDeleteNote,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
//import { ToastContainer } from "react-toastify"
import { useDeepCompareEffect } from "hooks"

function Notes() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [note, setNote] = useState(false)
  const [edit, setEdit] = useState(false)
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title ="Notes | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useDeepCompareEffect(() => {
    dispatch(onGetAllNote())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { notes, loading } = useSelector(state => state.note)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////
  useEffect(() => {
    dispatch(onGetAllNote())
  }, [dispatch])
  const handleAddClick = () => {
    navigate("/learning/notes/new")
  }

  const onClickDelete = arg => {
    setNote(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (note && note.NoteID) {
      dispatch(onDeleteNote(note.NoteID))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return <>{cellProps.row.original.Title}</>
        },
      },

      {
        Header: "Class",
        accessor: "ClassName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/learning/notes/" + cellProps.row.original.RowID}
                className="text-secondary"
              >
                <i className="bx bx-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Learning" breadcrumbItem="Notes List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn "
                        onClick={handleAddClick}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Notes
                      </Button>
                    </div>
                    {isLoading ? (
                      <Spinners setLoading={setLoading} />
                    ) : (
                      <TableContainer
                        columns={columns}
                        data={notes}
                        isGlobalFilter={true}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded mb-0 ms-auto"
                      />
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Notes.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Notes
