import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useDispatch } from "react-redux"

import {
  validExtensionsBasicSetting,
  maxFileSizeBasicSetting,
} from "pages/Utility/constants"

import { updateBasicSettings as OnUpdateBasicSettings } from "store/actions"

import { OnSymbol, Offsymbol } from "helpers/switch_helper"

const formdefault = {
  LightLogoFileName: "",
  DarkLogoFileName: "",
  SmallLightFileName: "",
  SmallDarkLogoFileName: "",
}
function SideBarImageTab() {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    LightLogoFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
    DarkLogoFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
    SmallLightFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
    SmallDarkLogoFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(OnUpdateBasicSettings({ ...getValues(), BasicSettingID: 1 }))
      reset(formdefault)
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Light Logo</Label>
            <Controller
              name="LightLogoFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="LightLogoFileName"
                    value={field.value?.LightLogoFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.LightLogoFileName}
                  />
                  {errors?.LightLogoFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LightLogoFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
            <div className="form-text">
              Recommended dimensions: 200px x 50px (max width: 200px)
            </div>
          </div>
          <div className="mb-3">
            <Label>Dark Logo</Label>
            <Controller
              name="DarkLogoFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="DarkLogoFileName"
                    value={field.value?.DarkLogoFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.DarkLogoFileName}
                  />
                  {errors?.DarkLogoFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.DarkLogoFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
            <div className="form-text">
              Recommended dimensions: 200px x 50px (max width: 200px)
            </div>
          </div>
          <div className="mb-3">
            <Label>Small Light Logo</Label>
            <Controller
              name="SmallLightFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="SmallLightFileName"
                    value={field.value?.SmallLightFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.SmallLightFileName}
                  />
                  {errors?.SmallLightFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.SmallLightFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
            <div className="form-text">
              Recommended dimensions: 50px x 50px (max width: 50px)
            </div>
          </div>
          <div className="mb-3">
            <Label>Small Dark Logo</Label>
            <Controller
              name="SmallDarkLogoFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="SmallDarkLogoFileName"
                    value={field.value?.SmallDarkLogoFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.SmallDarkLogoFileName}
                  />
                  {errors?.SmallDarkLogoFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.SmallDarkLogoFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
            <div className="form-text">
              Recommended dimensions: 50px x 50px (max width: 50px)
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {"Save"}
        </Button>
      </div>
    </FormProvider>
  )
}
export default SideBarImageTab
