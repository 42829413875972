/*Get SUBSCRIPTION */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL"

/**
 * Delete SUBSCRIPTION
 */
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS"
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL"
/**
 * Add SUBSCRIPTION
 */
export const ADD_NEW_SUBSCRIPTION = "ADD_NEW_SUBSCRIPTION"
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS"
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL"

/**
 * Get SUBSCRIPTION DETAILS
 */
export const GET_SUBSCRIPTION_DETAIL = "GET_SUBSCRIPTION_DETAIL"
export const GET_SUBSCRIPTION_DETAIL_SUCCESS = "GET_SUBSCRIPTION_DETAIL_SUCCESS"
export const GET_SUBSCRIPTION_DETAIL_FAIL = "GET_SUBSCRIPTION_DETAIL_FAIL"

/**
 * Edit SUBSCRIPTION
 */
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL"
/**
 * Reset SUBSCRIPTION
 */
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION"
export const RESET_SUBSCRIPTION_SUCCESS = "RESET_SUBSCRIPTION_SUCCESS"


/*Get SUBSCRIPTIONS TYPE */
export const GET_SUBSCRIPTION_TYPES = "GET_SUBSCRIPTION_TYPES"
export const GET_SUBSCRIPTION_TYPES_SUCCESS = "GET_SUBSCRIPTION_TYPES_SUCCESS"
export const GET_SUBSCRIPTION_TYPES_FAIL = "GET_SUBSCRIPTION_TYPES_FAIL"
