import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  addNewFlexible as onAddNewFlexible,
  updateFlexible as onUpdateFlexible,
} from "store/actions"
import PageModal from "components/Common/PageModal"
import Moment from "moment"

const daysofweek = [
  {
    DayID: 1,
    Name: "Sunday",
  },
  {
    DayID: 2,
    Name: "Monday",
  },
  {
    DayID: 3,
    Name: "Tuesday",
  },
  {
    DayID: 4,
    Name: "Wednesday",
  },
  {
    DayID: 5,
    Name: "Thursday",
  },
  {
    DayID: 6,
    Name: "Friday",
  },
  {
    DayID: 7,
    Name: "Saturday",
  },
]

const formdefault = {
  ProgramPayRateID: 0,
  RateName: "",
  IsSpecificPeriod: false,
  ProgramPayRateTypeID: 2,
  WeekDays: [],
}

const DailyModal = ({ show, onEdit, onCloseClick }) => {
  const dispatch = useDispatch()
  const { flexible } = useSelector(state => state.flexible)
  const [startDate, setStartDate] = useState(null)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramPayRateID: yup.number(),
    RateName: yup.string().required("Required"),
    WeekDays: yup.array().min(1).required("Required"),
    IsSpecificPeriod: yup.bool(),
    ProgramPayRateTypeID: yup.number(),
    StartDate: yup.string().when("IsSpecificPeriod", {
      is: value => value === true,
      then: schema => schema.required("Required"),
      otherwise: schema => schema.nullable(true),
    }),
    EndDate: yup.string().when("IsSpecificPeriod", {
      is: value => value === true,
      then: schema => schema.required("Required"),
      otherwise: schema => schema.nullable(true),
    }),
    Price: yup.number(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState
  const IsSpecificPeriod = watch("IsSpecificPeriod")

  useEffect(() => {
    if (onEdit) {
      if (!isEmpty(flexible)) {
        const response = {
          ...flexible,
          BillingCycles: {
            BillingCycleID: flexible.BillingCycleID,
            BillingCycle: flexible.BillingCycle,
          },
          StartDate:
            flexible.StartDate &&
            Moment(flexible.StartDate).format("DD MMM yyyy"),
          EndDate:
            flexible.EndDate && Moment(flexible.EndDate).format("DD MMM yyyy"),
        }
        reset(response)
      }
    } else {
      reset(formdefault)
    }
  }, [flexible, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      if (!onEdit) {
        dispatch(onAddNewFlexible(getValues()))
      } else {
        dispatch(onUpdateFlexible(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Define Special Daily Rates"
      size="md"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Rate Name</Label>
              <Controller
                name="RateName"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="RateName"
                      type="text"
                      required
                      invalid={!!errors.RateName}
                    />
                    {errors?.RateName?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.RateName?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <h5 className="mt-3">Schedule</h5>
            <span className="mb-3">When does this rate apply?</span>
            <div className="mt-3 mb-3">
              <Label>Weekdays</Label>
              <Controller
                name="WeekDays"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="WeekDays"
                      options={daysofweek}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.DayID}
                      required
                      isMulti
                      onChange={(e, { value }) => {
                        const values = e.map(v =>
                          daysofweek.find(item => item.DayID == v.DayID)
                        )
                        setValue("WeekDays", values)
                      }}
                      aria-invalid={!!errors.WeekDays}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.WeekDays?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.WeekDays?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Valid for a specific period of time only</Label>
              <div>
                <Controller
                  name="IsSpecificPeriod"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsSpecificPeriod"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </Col>
          {IsSpecificPeriod ? (
            <>
              <Col sm="6">
                <div className="mb-3">
                  <Label>Start Date</Label>
                  <Controller
                    name="StartDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="StartDate"
                          options={{
                            dateFormat: "d M, Y",
                            maxDate: getValues("EndDate"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("StartDate", dateStr)
                          }}
                        />
                        {errors?.StartDate?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.StartDate?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="mb-3">
                  <Label>End Date</Label>
                  <Controller
                    name="EndDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="EndDate"
                          options={{
                            dateFormat: "d M, Y",
                            minDate: getValues("StartDate"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("EndDate", dateStr)
                          }}
                        />
                        {errors?.EndDate?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.EndDate?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          <h5 className="mt-3">Price</h5>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Rate</Label>
              <Controller
                name="Price"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Price"
                      type="number"
                      invalid={!!errors.Price}
                    />
                    {errors?.Price?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Price?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

DailyModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default DailyModal
