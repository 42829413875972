import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import Moment from "moment"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useUserDetail } from "hooks"

// selector
import Select from "react-select"

import {
  getMessages as onGetMessages,
  getChildDetail as OnGetChildDetail,
} from "store/actions"
import EmptyContainer from "components/Common/EmptyContainer"
import TableContainer from "components/Common/TableContainer"
import { getChildImagePath } from "helpers/imageService"
import MessageModal from "pages/Child/modal/MessageModal"

function MessagingPage() {
  const dispatch = useDispatch()
  const { relations } = useUserDetail()

  const [state, setState] = useState({
    childID: 0,
    childRowID: "",
    showModal: false,
  })

  const updateState = data => setState(state => ({ ...state, ...data }))

  const { childID, showModal, childRowID } = state

  // search
  const handleSearch = () => {
    dispatch(onGetMessages(childID))
    dispatch(OnGetChildDetail(childRowID))
  }

  const { messages } = useSelector(state => state.child)

  const handleAddClick = () => {
    updateState({ showModal: true })
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM yyyy")
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        disableFilters: true,
        filterable: true,
        accessor: "ChildRowID",
        width: "20px",
        Cell: cellProps => {
          return (
            <img
              className="rounded-circle avatar-xs"
              src={getChildImagePath(cellProps.value, "image.jpeg")}
              alt="Avatar"
            />
          )
        },
      },
      {
        Header: "Name",
        accessor: "ChildName",
        Cell: cellProps => {
          return (
            <>
              <div>{cellProps.value}</div>
            </>
          )
        },
      },
      {
        Header: "Topic",
        accessor: "Topic",
        Cell: cellProps => {
          return (
            <>
              <span>{cellProps.row.original.Topic}</span>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/child/messaging/${cellProps.row.original.ChildRowID}/${cellProps.row.original.RowID}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const onCloseModal = () => {
    updateState({ showModal: false })
  }

  return (
    <React.Fragment>
      {showModal && (
        <MessageModal show={showModal} onCloseClick={() => onCloseModal()} />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="" breadcrumbItem="Messaging" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="g-3">
                    <Col xxl={2} lg={6}>
                      <div className="position-relative">
                        <Select
                          className="select2"
                          onChange={e => {
                            updateState({
                              childID: e.ChildID,
                              childRowID: e.RowID,
                            })
                          }}
                          options={relations}
                          getOptionLabel={option => option.Name}
                          getOptionValue={option => option.ChildID}
                        />
                      </div>
                    </Col>
                    <Col xxl={2} lg={2}>
                      <div className="position-relative h-100 hstack gap-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleSearch()}
                        >
                          <i className="bx bx-search-alt align-middle"></i>{" "}
                          Proceed
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      {messages?.filter(x => !x.Declined && !x.ApprovalRequired)
                        .length == 0 ? (
                        <EmptyContainer
                          backURL=""
                          message="No messages found for child"
                          linkText=""
                        />
                      ) : (
                        <TableContainer
                          columns={columns}
                          data={messages?.filter(
                            x => !x.Declined && !x.ApprovalRequired
                          )}
                          isAddOption={true}
                          addOptionText="Messsage Staff"
                          handleAddClick={handleAddClick}
                          customPageSize={10}
                          isPagination={true}
                          filterable={false}
                          iscustomPageSizeOptions={true}
                          tableClass="align-middle table-nowrap table-check"
                          theadClass="table-light"
                          pagination="pagination pagination-rounded mb-0 ms-auto"
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MessagingPage
