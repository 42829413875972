import {
  ADD_EXTERNAL_PARENT,
  ADD_EXTERNAL_PARENT_SUCCESS,
  ADD_EXTERNAL_PARENT_FAIL,
  VERIFY_EXTERNAL_PARENT,
  VERIFY_EXTERNAL_PARENT_SUCCESS,
  VERIFY_EXTERNAL_PARENT_FAIL,
} from "./actionTypes"

export const addExternalParent = parent => ({
  type: ADD_EXTERNAL_PARENT,
  payload: parent,
})

export const addExternalParentSuccess = parent => ({
  type: ADD_EXTERNAL_PARENT_SUCCESS,
  payload: parent,
})

export const addExternalParentFail = error => ({
  type: ADD_EXTERNAL_PARENT_FAIL,
  payload: error,
})

export const verifyExternalParent = rowid => ({
  type: VERIFY_EXTERNAL_PARENT,
  payload: rowid,
})

export const verifyExternalParentSuccess = parent => ({
  type: VERIFY_EXTERNAL_PARENT_SUCCESS,
  payload: parent,
})

export const verifyExternalParentFail = error => ({
  type: VERIFY_EXTERNAL_PARENT_FAIL,
  payload: error,
})
