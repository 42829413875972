const updateFavicon = faviconUrl => {
  const API_ROOT_URL = process.env.REACT_APP_URL
  const fallbackFavicon = `${API_ROOT_URL}/favicon.png` // Local fallback favicon
  const link =
    document.querySelector("link[rel~='icon']") ||
    document.createElement("link")
  link.rel = "icon"
  link.href = faviconUrl || fallbackFavicon
  // If the favicon tag is newly created, append it to the head
  if (!link.parentElement) {
    document.head.appendChild(link)
  }
}

// Set the favicon dynamically
export const setFavicon = basicSetting => {
  const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL
  if (API_ROOT_URL) {
    //const dynamicFaviconUrl = `${API_ROOT_URL}/_files/Theme/favicon.jpg`
    const dynamicFaviconUrl = `${API_ROOT_URL}/_files/Theme/${basicSetting?.FaviconFileName}`
    updateFavicon(dynamicFaviconUrl)
  } else {
    console.warn("REACT_APP_API_ROOT_URL is not defined.")
  }
}
