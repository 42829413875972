import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalBody,
  InputGroup,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import { updateProfileOperatingHour as onUpdateProfileOperatingHour } from "store/actions"
import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"
import DetailArray from "./DetailArray"
import Spinners from "components/Common/Spinner"

function OperateHourModal({ profileID, show, onCloseClick }) {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CenterOpeningID: yup.number(),
    Availability: yup.string(),
    Linked: yup.boolean(),
    CenterOpeningDetail: yup
      .array()
      .of(
        yup.object().shape({
          CenterOpeningDetailID: yup.number(),
          StartTime: yup.string(),
          EndTime: yup.string(),
        })
      )
      .when("Linked", {
        is: value => value === true,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      }),
  })

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const { fields, append, remove } = useFieldArray({
    name: "availability",
    control,
  })

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { openings, loading } = useSelector(state => state.centerProfile)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onUpdateProfileOperatingHour(profileID, getValues()))
      onCloseClick()
    }
  }

  useEffect(() => {
    if (isEmpty(openings)) {
      return
    }
    reset({ availability: openings })
  }, [openings])

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Center Opening Hours"
      size="lg"
    >
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <FormProvider {...methods}>
          {fields.map((item, index) => {
            return (
              <Row key={`availability${index}`}>
                <Col sm={3}>
                  <Controller
                    control={control}
                    name={`availability[${index}].Linked`}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          defaultChecked={field.value}
                          id={`availability[${index}].Linked`}
                          type="checkbox"
                          className="form-check-input me-2"
                        />
                      </>
                    )}
                  />
                  {item.Availability}
                </Col>
                <Col sm={9}>
                  {watch(`availability[${index}].Linked`) && (
                    <DetailArray
                      key={`detailarray${index}`}
                      nestIndex={index}
                    />
                  )}
                </Col>
              </Row>
            )
          })}
        </FormProvider>
      )}
    </PageModal>
  )
}
export default OperateHourModal
