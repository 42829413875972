import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"

import classnames from "classnames"
import BasicInfoTab from "./tabs/BasicInfoTab"
import {
  getChildDetail as onGetChildDetail,
  getParentProvidedFood as onGetParentProvidedFood,
  getNotes as onGetNotes,
  getChildAvailability as onGetChildAvailability,
  getChildRelations as onGetChildRelations,
} from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import Document from "components/Common/Document"
import { EnumObjectTypes } from "helpers/enum_helper"
import Immunization from "./tabs/Immunization"
import ScheduleTab from "./tabs/ScheduleTab"
import HappeningTab from "./tabs/HappeningTab"
import HomeClass from "./misc/HomeClass"
import FoodModal from "./modal/FoodModal"
import ProvidedFood from "./misc/ProvidedFood"
import NotesModal from "./modal/NotesModal"
import Notes from "./misc/Notes"
import AvailabilityModal from "./modal/AvailabilityModal"
import Availability from "./misc/Availability"
import Relations from "./misc/Relations"
import TagTab from "./tabs/TagTab"
import MessageTab from "./tabs/MessageTab"
import ServiceTab from "./tabs/ServiceTab"

const ChildAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const [foodModal, setFoodModal] = useState(false)
  const [notesModal, setNotesModal] = useState(false)
  const [availabilityModal, setAvailabilityModal] = useState(false)
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title =
    "Children | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Child")
    } else {
      setHeader("Edit Child")
      dispatch(onGetChildDetail(id))
    }
  }, [routeParams])

  //////////////////Child STATE/////////////////////
  const data = useSelector(state => state.child)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    if (!isEmpty(data.child)) {
      dispatch(onGetParentProvidedFood(data.child.ChildID))
      dispatch(onGetNotes(data.child.ChildID))
      dispatch(onGetChildAvailability(data.child.ChildID))
      dispatch(onGetChildRelations(data.child.ChildID))
    }
  }, [data.child])

  const onFoodModalClick = () => {
    setFoodModal(true)
  }

  const onNotesModalClick = () => {
    setNotesModal(true)
  }

  const onAvailabilityModalClick = () => {
    setAvailabilityModal(true)
  }

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  const onCloseModal = () => {
    setFoodModal(false)
    setNotesModal(false)
    setAvailabilityModal(false)
  }

  const MiscLeftSection = (heading, buttonText, onClick, children) => {
    return (
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h5 className="m-0 pt-2">{heading}</h5>
          </div>
          <div hidden={isEmpty(buttonText)}>
            <Button
              className="btn btn-light waves-effect btn btn-light btn-sm"
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        {children}
      </div>
    )
  }

  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  return (
    <React.Fragment>
      {!isEmpty(data.child) ? (
        <>
          {foodModal && (
            <FoodModal show={foodModal} onCloseClick={() => onCloseModal()} />
          )}
          {notesModal && (
            <NotesModal show={notesModal} onCloseClick={() => onCloseModal()} />
          )}
          {availabilityModal && (
            <AvailabilityModal
              show={availabilityModal}
              onCloseClick={() => onCloseModal()}
            />
          )}
        </>
      ) : (
        ""
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Child" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.child) ? (
            <EmptyContainer
              backURL="/children"
              message="There is no such child!"
              linkText="Go to Children Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    {routeParams.id !== "new" ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Misc", "2")}
                        {NavItemTab("Messaging", "3")}
                        {NavItemTab("Tags", "4")}
                        {NavItemTab("Documents", "5")}
                        {NavItemTab("Happenings", "6")}
                        {/* {NavItemTab("Immunization", "7")} */}
                        {NavItemTab("Services", "8")}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab />
                          </Col>
                        </Row>
                      </TabPane>
                      {routeParams.id !== "new" ? (
                        <>
                          <TabPane tabId="2">
                            <Row>
                              <Col className="col-3">
                                <div className="p-3 rounded border h-100">
                                  {MiscLeftSection(
                                    "Home Class",
                                    "",
                                    () => {},
                                    <HomeClass />
                                  )}
                                  {MiscLeftSection(
                                    "Parent Provided Food",
                                    "Edit",
                                    () => onFoodModalClick(),
                                    <ProvidedFood />
                                  )}
                                  {MiscLeftSection(
                                    "Notes",
                                    "Edit",
                                    () => onNotesModalClick(),
                                    <Notes />
                                  )}
                                  {MiscLeftSection(
                                    "Desired Hours",
                                    "Edit",
                                    () => onAvailabilityModalClick(),
                                    <Availability />
                                  )}
                                </div>
                              </Col>
                              <Col className="col-9  ">
                                <div className="p-3 rounded border">
                                  <ScheduleTab />
                                </div>
                                <div className="p-3 mt-3 border rounded flex-grow-1">
                                  <Relations />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="3">
                            <Row>
                              <Col sm="12">
                                <MessageTab />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="4">
                            <Row>
                              <Col sm="12">
                                <TagTab />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="5">
                            <Row>
                              <Col sm="12">
                                <Document
                                  objectID={data.child.ChildID}
                                  objectTypeID={EnumObjectTypes.Children}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="6">
                            <Row>
                              <Col sm="12">
                                <HappeningTab />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="7">
                            <Row>
                              <Col sm="2"></Col>
                              <Col sm="8">
                                <Immunization />
                              </Col>
                              <Col sm="2"></Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="8">
                            <Row>
                              <Col sm="12">
                                <ServiceTab />
                              </Col>
                            </Row>
                          </TabPane>
                        </>
                      ) : (
                        ""
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChildAddEdit
