import React, { useEffect, useRef } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { isEmpty } from "lodash"
//Date filter
import Moment from "moment"
import {
  validExtensionsBasicSetting,
  maxFileSizeBasicSetting,
} from "pages/Utility/constants"
import { updateBasicSettings as OnUpdateBasicSettings } from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

const formdefault = {
  CompanyName: "",
  SiteName: "",
  SiteDescription: "",
  FaviconFileName: null, // Default to null
}
function BasicSettingTab() {
  const dispatch = useDispatch()
  //const faviconFileNameRef = useRef("") // Ref for file input
  const { basicSetting } = useSelector(state => state.Layout)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    //BasicSettingID: yup.number(),
    CompanyName: yup.string().required("Required"),
    SiteName: yup.string().required("Required"),
    SiteDescription: yup.string().required("Required"),
    FaviconFileName: yup
      .mixed()
      .nullable()
      .test("fileType", "File must be a PNG, JPG, or JPEG", file => {
        if (!file) return true // If no file is uploaded, skip validation
        return file ? validExtensionsBasicSetting.includes(file.type) : true
      })
      .test("fileSize", "File must be smaller than 1MB", file => {
        if (!file) return true // If no file is uploaded, skip validation
        return file ? file.size <= maxFileSizeBasicSetting : true
      }),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updatePaypalState() {
      //reset form with server data
      if (basicSetting) {
        const response = {
          ...basicSetting,
          FaviconFileName: null,
        }
        reset(response)
      }
    }

    updatePaypalState()
  }, [dispatch, basicSetting])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(OnUpdateBasicSettings({ ...getValues(), BasicSettingID: 1 }))
     // reset(formdefault)
      // if (faviconFileNameRef.current) {
      //   faviconFileNameRef.current.value = "" // Clear file input value
      // }
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Company Name</Label>
            <Controller
              name="CompanyName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="CompanyName"
                    type="text"
                    required
                    invalid={!!errors.CompanyName}
                  />
                  {errors?.CompanyName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.CompanyName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>

          <div className="mb-3">
            <Label>Site Name</Label>
            <Controller
              name="SiteName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="SiteName"
                    type="text"
                    required
                    invalid={!!errors.SiteName}
                  />
                  {errors?.SiteName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.SiteName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Description</Label>
            <Controller
              name="SiteDescription"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="SiteDescription"
                    type="textarea"
                    required
                    invalid={!!errors.SiteDescription}
                  />
                  {errors?.SiteDescription?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.SiteDescription?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Favicon File</Label>
            <Controller
              name="FaviconFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="FaviconFileName"
                   // ref={faviconFileNameRef} // Attach ref to the file input
                    value={field.value?.FaviconFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    invalid={!!errors.FaviconFileName}
                  />
                  {errors?.FaviconFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.FaviconFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
            <div className="form-text">Recommended size:32×32px or 64×64px</div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {"Save"}
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicSettingTab
