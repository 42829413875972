import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  getChildren as onGetChildren,
  getChildAbsentTypes as onGetChildAbsentTypes,
  addChildSchedule as onAddChildSchedule,
} from "store/actions"
import AssignMultiple from "components/Common/AssignMultipleDates"
import AlertContainer from "components/Common/AlertContainer"
import { RESET_CHILD_SCHEDULE } from "store/scheduling/child/actionTypes"

const AbsentModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ScheduleDate: yup.string().required("Required"),
    ScheduleStartTime: yup.string().required("Required"),
    ScheduleEndTime: yup.string().required("Required"),
    // .min(
    //   yup.ref("ScheduleStartTime"),
    //   "End time cannot be earlier than start time"
    // ),
    ScheduleTypeID: yup.string(),
    Children: yup.object().required("Required"),
    AbsentTypes: yup.object().required("Required"),
    AbsentOtherReason: yup.string().when("AbsentTypes", {
      is: value => value.AbsentTypeID == "5", //Other
      then: schema => schema.required("Required"),
      otherwise: schema => schema,
    }),
    AbsentComment: yup.string(),
    MultipleDays: yup.bool(),
    DaysOfWeek: yup.array().when("MultipleDays", {
      is: value => value === true,
      then: schema =>
        schema
          .min(1, "Select at least one weekday")
          .required("Select at least one weekday"),
      otherwise: schema => schema,
    }),
    StartDate: yup.string().when("MultipleDays", {
      is: value => value === true,
      then: schema => schema.required("Required"),
      otherwise: schema => schema,
    }),
    EndDate: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ScheduleDate: "",
      ScheduleStartTime: "",
      ScheduleEndTime: "",
      ScheduleTypeID: 2,
      Children: "",
      AbsentTypes: "",
      AbsentOtherReason: "",
      AbsentComment: "",
      MultipleDays: false,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const currentAbsentTypes = watch("AbsentTypes")

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetChildren())
      dispatch(onGetChildAbsentTypes())
      dispatch({ type: RESET_CHILD_SCHEDULE })
    }

    updateState()
  }, [dispatch])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const { children } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////CLASS SCHEDULE STATE/////////////////////
  const { absenttypes } = useSelector(state => state.childSchedules)
  //////////////////////////////////////

  //////////////////CHILD SCHEDULE STATE/////////////////////
  const { error, closeModal } = useSelector(state => state.childSchedules)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddChildSchedule(getValues()))
    }
  }

  useEffect(() => {
    if (closeModal) {
      onCloseClick()
    }
  }, [closeModal])

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Plan Absent
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <AlertContainer message={error?.data} color="danger" />
            <Col lg={12}>
              <div className="mb-3">
                <Label>Schedule Date</Label>
                <Controller
                  name="ScheduleDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="ScheduleDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("ScheduleDate", dateStr)
                        }}
                        required
                      />
                      {errors?.ScheduleDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ScheduleDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Schedule Start Time</Label>
                <Controller
                  name="ScheduleStartTime"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="ScheduleStartTime"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            maxTime: getValues("ScheduleEndTime"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("ScheduleStartTime", selectedDates[0])
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.ScheduleStartTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ScheduleStartTime?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Schedule End Time</Label>
                <Controller
                  name="ScheduleEndTime"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="ScheduleEndTime"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            minTime: getValues("ScheduleStartTime"),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("ScheduleEndTime", selectedDates[0])
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.ScheduleEndTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ScheduleEndTime?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Child</Label>
                <Controller
                  name="Children"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Children"
                        options={children.filter(child => child.IsActive == 1)}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.ChildID}
                        required
                        aria-invalid={!!errors.Children}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Children?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Children?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Reason</Label>
                <Controller
                  name="AbsentTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="AbsentTypes"
                        options={absenttypes}
                        getOptionLabel={option => option.AbsentType}
                        getOptionValue={option => option.AbsentTypeID}
                        required
                        aria-invalid={!!errors.AbsentTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.AbsentTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.AbsentTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              {currentAbsentTypes.AbsentTypeID === 5 ? (
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Controller
                    name="AbsentOtherReason"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          id="AbsentOtherReason"
                          type="text"
                          invalid={!!errors.AbsentOtherReason}
                        />
                        {errors?.AbsentOtherReason?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.AbsentOtherReason?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="mb-3">
                <Label>Comment</Label>
                <Controller
                  name="AbsentComment"
                  control={control}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      rows={3}
                      className="form-control mb-3"
                      id="AbsentComment"
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <AssignMultiple />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

AbsentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default AbsentModal
