import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import CarouselPage from "./CarouselPage"
import Spinners from "components/Common/Spinner"
import ButtonLoader from "components/Common/ButtonLoader"

const Login = props => {
  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////
  //meta title
  document.title =
    "Login | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setLoading(true)
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
    loading: login.loading,
  }))

  const { error, loading } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  useEffect(() => {
    if (!loading) {
      setLoading(false)
    }
  }, [loading, basicSetting])

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  const [passwordShow, setPasswordShow] = useState(false)

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0 vh-100 d-flex flex-column">
          <Row className="g-0 flex-grow-1">
            <CarouselPage />

            <Col xl={3} className="d-flex flex-column">
              <div className="auth-full-page-content p-md-5 p-4 flex-grow-1 d-flex flex-column">
                <div className="w-100 flex-grow-1 d-flex flex-column justify-content-between">
                  <Link to="/dashboard" className="d-block card-logo">
                    <img
                      src={
                        basicSetting && basicSetting?.LoginDarkLogoFileName
                          ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.LoginDarkLogoFileName}`
                          : process.env.REACT_APP_LOGIN_LOGO_DARK
                      }
                      alt=""
                      style={{
                        maxHeight: window.innerWidth >= 768 ? "150px" : "100px",
                      }}
                      className="logo-dark-element mx-auto mb-4 mb-lg-0"
                    />
                    <img
                      src={
                        basicSetting && basicSetting?.LoginDarkLogoFileName
                          ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.LoginLightLogoFileName}`
                          : process.env.REACT_APP_LOGIN_LOGO_LIGHT
                      }
                      alt=""
                      style={{
                        maxHeight: window.innerWidth >= 768 ? "150px" : "100px",
                      }}
                      className="logo-light-element mx-auto mb-4 mb-lg-0"
                    />
                  </Link>

                  <div>
                    <div>
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to{" "}
                        {basicSetting?.SiteName || process.env.REACT_APP_NAME}.
                      </p>
                    </div>

                    <div className="mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">
                            {error.data.error_description}
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          {isLoading ? (
                            <ButtonLoader />
                          ) : (
                            <>
                              <button
                                className="btn btn-primary btn-block mb-3"
                                type="submit"
                              >
                                Log In
                              </button>
                              <button
                                className="btn btn-secondary btn-block"
                                type="button"
                                onClick={() => navigate("/external/parent")}
                              >
                                External Registration
                              </button>
                            </>
                          )}
                        </div>

                        <div className="mt-4 text-center d-none">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                                onClick={e => {
                                  e.preventDefault()
                                  socialResponse("facebook")
                                }}
                              >
                                <i className="mdi mdi-facebook" />
                              </Link>
                            </li>
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                                onClick={e => {
                                  e.preventDefault()
                                  socialResponse("google")
                                }}
                              >
                                <i className="mdi mdi-google" />
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()}{" "}
                      {basicSetting?.SiteName || process.env.REACT_APP_NAME}.
                      Crafted with <i className="mdi mdi-heart text-danger" />{" "}
                      by{" "}
                      {basicSetting?.CompanyName ||
                        process.env.REACT_APP_COMPANY_NAME}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
