import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Button,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Formik Validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// action
//import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
// action
import { resetUserPassword as onResetUserPassword } from "../../store/actions"
const ForgetPasswordPage = props => {
  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////
  //meta title
  document.title =
    "Forget Password | " + basicSetting?.SiteName ||
    process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const [resetStatus, setResetStatus] = useState(0)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      //dispatch(userForgetPassword(values, props.history))
    },
  })
  const selectForgotPasswordState = state => state.user
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    forgetPassword => ({
      forgetError: forgetPassword.resetUserPassword == 2 ? "error" : null,
      forgetSuccessMsg:
        forgetPassword.resetUserPassword == 1 ? "success" : null,
    })
  )

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  )
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Email: yup.string().required("Required").email("Must be a valid Email"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Email: "",
    },
    resolver: yupResolver(schema),
  })

  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const handleResetUserPaasword = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(onResetUserPassword(getValues("Email")))
      setResetStatus(1)
      reset()
    }
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>
                          Sign in to continue to {basicSetting?.SiteName || process.env.REACT_APP_NAME}.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={process.env.REACT_APP_LOGO}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {resetStatus == 1 &&
                    forgetError &&
                    forgetError == "error" ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {"Email does not exist"}
                      </Alert>
                    ) : null}
                    {resetStatus == 1 &&
                    forgetSuccessMsg &&
                    forgetSuccessMsg == "success" ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {
                          "Reset link is sent to your mailbox, check there first"
                        }
                      </Alert>
                    ) : null}

                    <FormProvider {...methods}>
                      {/* 
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    > */}
                      <div className="mb-3">
                        {/* <Label className="form-label">Email</Label> */}
                        {/* <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null} */}
                        <Label>Email</Label>
                        <Controller
                          name="Email"
                          control={control}
                          render={({ field }) => (
                            <>
                              <Input
                                {...field}
                                id="Email"
                                type="email"
                                required
                                placeholder="Enter email"
                                invalid={!!errors.Email}
                              />
                              {errors?.Email?.message ? (
                                <FormFeedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {errors?.Email?.message}
                                </FormFeedback>
                              ) : null}
                            </>
                          )}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            onClick={handleResetUserPaasword}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </FormProvider>
                    {/* </Form> */}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {basicSetting?.SiteName || process.env.REACT_APP_NAME}.
                  Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                  {process.env.REACT_APP_COMPANY_NAME}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
