import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Card,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { getAuthUser } from "helpers/token_helper"
import { getProfile as onGetProfile } from "store/actions"

import { isEmpty } from "lodash"

// action
import { updatePassword as onUpdatePassword } from "../../store/actions"

const ChangePasswordPage = props => {
  //meta title

  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////

  document.title =
    "Change Password | " +
    (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  const dispatch = useDispatch()

  const [profile, setProfile] = useState({})

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    UserID: yup.number(),
    Password: yup.string().required("Password is required"),
    ChangePassword: yup
      .string()
      .oneOf([yup.ref("Password"), null], "Passwords must match"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      UserID: 0,
      Password: "",
      ChangePassword: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(
        onUpdatePassword({ ...getValues(), UserID: userprofile?.UserID })
      )
      reset()
    }
  }
  const { success } = useSelector(state => state.Profile)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Change Password" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    hidden={!success.Message}
                    className="alert alert-success fade show"
                  >
                    {success.Message}
                  </div>
                  <FormProvider {...methods}>
                    <div className="mb-3">
                      <Label>User Name</Label>
                      <div>{userprofile?.Username}</div>
                    </div>
                    <div className="mb-3">
                      <Label>Password</Label>
                      <Controller
                        name="Password"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              id="Password"
                              type="password"
                              required
                              invalid={!!errors.Password}
                            />
                            {errors?.Password?.message ? (
                              <FormFeedback type="invalid" className="d-block">
                                {errors?.Password?.message}
                              </FormFeedback>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label>Confirm Password</Label>
                      <Controller
                        name="ChangePassword"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              id="ChangePassword"
                              type="password"
                              required
                              invalid={!!errors.ChangePassword}
                            />
                            {errors?.ChangePassword?.message ? (
                              <FormFeedback type="invalid" className="d-block">
                                {errors?.ChangePassword?.message}
                              </FormFeedback>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        color="primary"
                        className="btn"
                        onClick={handleSave}
                      >
                        Proceed
                      </Button>
                    </div>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ChangePasswordPage)
