import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input, Label, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//Date filter
import Moment from "moment"

const DateInput = ({ label, id, required, maxDate, minDate }) => {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <>
            <InputGroup>
              <Flatpickr
                {...field}
                className="form-control d-block"
                id={id}
                options={{
                  dateFormat: "d M, Y",
                  maxDate: getValues(maxDate),
                  minDate: getValues(minDate),
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  setValue(id, dateStr)
                }}
              />
              <div className="input-group-text">
                <i
                  className="mdi mdi-trash-can-outline"
                  aria-hidden="true"
                  onClick={() => setValue(id, "")}
                />
              </div>
            </InputGroup>
            {errors[id]?.message ? (
              <FormFeedback type="invalid" className="d-block">
                {errors[id]?.message}
              </FormFeedback>
            ) : null}
          </>
        )}
      />
    </React.Fragment>
  )
}

DateInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
}

export default DateInput
