import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Subscription Redux States
import {
  GET_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  ADD_NEW_SUBSCRIPTION,
  GET_SUBSCRIPTION_DETAIL,
  UPDATE_SUBSCRIPTION,
  RESET_SUBSCRIPTION,
  GET_SUBSCRIPTION_TYPES
} from "./actionTypes"
import {
  getSubscriptionsFail,
  getSubscriptionsSuccess,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  addSubscriptionSuccess,
  addSubscriptionFail,
  getSubscriptionDetailSuccess,
  getSubscriptionDetailFail,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  resetSubscriptionSuccess,
  getSubscriptionTypesFail,
  getSubscriptionTypesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSubscriptions,
  deleteSubscription,
  addNewSubscription,
  getSubscriptionDetail,
  updateSubscription,
  getSubscriptionTypes
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchSubscriptions() {
  try {
    const response = yield call(getSubscriptions)
    yield put(getSubscriptionsSuccess(response))
  } catch (error) {
    yield put(getSubscriptionsFail(error))
  }
}

function* onDeleteSubscription({ payload: licence }) {
  try {
    const response = yield call(deleteSubscription, licence)
    yield put(deleteSubscriptionSuccess(response))
    toast.success("Subscription deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteSubscriptionFail(error))
    toast.error("Subscription deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewSubscription({ payload: licence }) {
  try {
    const response = yield call(addNewSubscription, licence)
    yield put(addSubscriptionSuccess(response[0]))
    toast.success("Subscription added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addSubscriptionFail(error))
    toast.error("Subscription addition failed", { autoClose: 2000 })
  }
}

function* fetchSubscriptionDetail({ licenceId }) {
  try {
    const response = yield call(getSubscriptionDetail, licenceId)
    let result = response[0]
    result = {
      ...result
     
    }
    yield put(getSubscriptionDetailSuccess(result))
  } catch (error) {
    yield put(getSubscriptionDetailFail(error))
  }
}

function* onUpdateSubscription({ payload: licence }) {
  try {
    const response = yield call(updateSubscription, licence)
    let result = response[0]

    yield put(updateSubscriptionSuccess(result))
    toast.success("Subscription updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateSubscriptionFail(error))
    toast.error("Subscription updation failed", { autoClose: 2000 })
  }
}

function* onResetSubscription() {
  try {
    yield put(resetSubscriptionSuccess({}))
  } catch (error) {}
}
function* fetchSubscriptionTypes() {
  try {
    const response = yield call(getSubscriptionTypes)
    yield put(getSubscriptionTypesSuccess(response))
  } catch (error) {
    yield put(getSubscriptionTypesFail(error))
  }
}
function* subscriptionSaga() {
  yield takeEvery(GET_SUBSCRIPTIONS, fetchSubscriptions)
  yield takeEvery(DELETE_SUBSCRIPTION, onDeleteSubscription)
  yield takeEvery(ADD_NEW_SUBSCRIPTION, onAddNewSubscription)
  yield takeEvery(GET_SUBSCRIPTION_DETAIL, fetchSubscriptionDetail)
  yield takeEvery(UPDATE_SUBSCRIPTION, onUpdateSubscription)
  yield takeEvery(RESET_SUBSCRIPTION, onResetSubscription)
  yield takeEvery(GET_SUBSCRIPTION_TYPES, fetchSubscriptionTypes)
}

export default subscriptionSaga
