import PropTypes, { object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

import FileUpload from "components/Common/FileUpload"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  getClasses as onGetClasses,
  addActivitiy as onAddActivitiy,
  updateActivitiy as onUpdateActivitiy,
  downloadActivityAttachment as onDownloadActivityAttachment,
} from "store/actions"
import { isEmpty } from "lodash"
import { downloadActivityAttachment } from "helpers/backend_helper"

const ActivityModal = ({
  show,
  isEditMode,
  isViewMode,
  isParent,
  onCloseClick,
}) => {
  const dispatch = useDispatch()

  //////////////////ACTIVITY STATE/////////////////////
  const { activity, blob } = useSelector(state => state.activity)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ActivityStartDate: yup.string().required("Required"),
    ActivityStartTime: yup.string().nullable(),
    // .test(
    //   "start_time_test",
    //   "Start time must be before end time",
    //   function (value) {
    //     const { ActivityEndTime } = this.parent
    //     return isSameOrBefore(value, ActivityEndTime)
    //   }
    // ),
    ActivityEndTime: yup.string().nullable(),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    Name: yup.string().required("Required"),
    Description: yup.string().nullable(),
    LearningIndicator: yup.string().nullable(),
    TeacherNote: yup.string().nullable(),
    SuppliesList: yup.string().nullable(),
    Link: yup.string().nullable(),
    LinkTitle: yup.string().nullable(),
    IsUseTeacher: yup.bool().nullable(),
    IsUseChildren: yup.bool().nullable(),
    IsUseDailyReport: yup.bool().nullable(),
    Attachment: yup.array().of(
      yup
        .mixed()
        .test("is-valid-type", "Not a valid file type", value => {
          if (!value) return true // Allow empty values
          return (
            isValidFileType(value && value.name.toLowerCase(), "file") ||
            isValidFileType(value && value.name.toLowerCase(), "image")
          )
        })
        .test("is-valid-size", "Max allowed size is 5MB", value => {
          if (!value) return true // Allow empty values
          return value.size <= MAX_FILE_SIZE // Convert KB to bytes
        })
    ),
  })

  const isSameOrBefore = (startTime, endTime) => {
    return Moment(startTime, "HH:mm A").isSameOrBefore(
      Moment(endTime, "HH:mm A")
    )
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ActivityStartDate: "",
      ActivityStartTime: "",
      ActivityEndTime: "",
      Classes: "",
      Name: "",
      Description: "",
      LearningIndicator: "",
      TeacherNote: "",
      SuppliesList: "",
      Link: "",
      LinkTitle: "",
      IsUseTeacher: false,
      IsUseChildren: false,
      IsUseDailyReport: false,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetClasses())
    }

    updateState()
  }, [dispatch])

  useEffect(() => {
    //reset form
    if (!isEmpty(activity)) {
      reset(activity)
    }
  }, [activity])

  const removeFile = attachmentID => {
    const files = getValues("Attachments")
    const index = files.findIndex(item => item.AttachmentID === attachmentID)

    if (index !== -1) {
      const updatedData = [...files]
      updatedData[index] = { ...updatedData[index], Active: false }

      setValue("Attachments", updatedData)
    }
  }

  const downloadFile = async obj => {
    try {
      const response = await downloadActivityAttachment(obj.AttachmentID)

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })

      // Create a link element, set its href to the blob URL, and trigger a download
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = obj.FileName
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      document.body.removeChild(link)
    } catch (error) {
      console.error("Error downloading the file", error)
    }
  }

  //////////////////CLASS SCHEDULE STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      if (isEditMode) {
        dispatch(onUpdateActivitiy(getValues()))
      } else {
        dispatch(onAddActivitiy(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        {isViewMode ? "View " : isEditMode ? "Update " : "Add "} Activity
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Date</Label>
                {isViewMode ? (
                  <div>{getValues("ActivityStartDate")}</div>
                ) : (
                  <Controller
                    name="ActivityStartDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="ActivityStartDate"
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("ActivityStartDate", dateStr)
                          }}
                          required
                        />
                        {errors?.ActivityStartDate?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ActivityStartDate?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Activity Start Time</Label>
                {isViewMode ? (
                  <div>
                    {isEmpty(getValues("ActivityStartTime"))
                      ? "-"
                      : Moment(getValues("ActivityStartTime")).format(
                          "hh:mm a"
                        )}
                  </div>
                ) : (
                  <Controller
                    name="ActivityStartTime"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputGroup>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id="ActivityStartTime"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                            }}
                            value={
                              field.value
                                ? Moment(field.value).format("h:mm a")
                                : ""
                            }
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue(
                                "ActivityStartTime",
                                Moment(selectedDates[0]).format("h:mm a")
                              )
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        {errors?.ActivityStartTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ActivityStartTime?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                )}
                <Link
                  hidden={isViewMode}
                  onClick={() => setValue("ActivityStartTime", "")}
                >
                  Clear
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label>Activity End Time</Label>
                {isViewMode ? (
                  <div>
                    {isEmpty(getValues("ActivityEndTime"))
                      ? "-"
                      : Moment(getValues("ActivityEndTime")).format("hh:mm a")}
                  </div>
                ) : (
                  <Controller
                    name="ActivityEndTime"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputGroup>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id="ActivityEndTime"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                              minTime: getValues("ActivityStartTime"),
                            }}
                            value={
                              field.value
                                ? Moment(field.value).format("h:mm a")
                                : ""
                            }
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue(
                                "ActivityEndTime",
                                Moment(selectedDates[0]).format("h:mm a")
                              )
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        {errors?.ActivityEndTime?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.ActivityEndTime?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                )}
                <Link
                  hidden={isViewMode}
                  onClick={() => setValue("ActivityEndTime", "")}
                >
                  Clear
                </Link>
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Class</Label>
                {isViewMode ? (
                  <div>{getValues("ClassName")}</div>
                ) : (
                  <Controller
                    name="Classes"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          id="Classes"
                          options={classes
                            .filter(cls => cls.IsActive == 1)
                            .filter(cls => cls.IsSystem == 0)
                            .filter(cls => cls.ClassTypeID == 1)}
                          getOptionLabel={option => option.Title}
                          getOptionValue={option => option.ClassID}
                          required
                          aria-invalid={!!errors.Classes}
                          classNamePrefix="select2-selection"
                        />
                        {errors?.Classes?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Classes?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                )}
              </div>
              <div className="mb-3">
                <Label>Activity name</Label>
                {isViewMode ? (
                  <div>{getValues("Name")}</div>
                ) : (
                  <Controller
                    name="Name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          id="Name"
                          type="text"
                          invalid={!!errors.Name}
                        />
                        {errors?.Name?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Name?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                )}
              </div>
              <div className="mb-3">
                <Label>Activity description</Label>
                {isViewMode ? (
                  <div>{getValues("Description")}</div>
                ) : (
                  <Controller
                    name="Description"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id="Description"
                      />
                    )}
                  />
                )}
              </div>
              <div hidden={isParent} className="mb-3">
                <Label>Learning indicators</Label>
                <small className="m-2 text-muted">
                  visible to teachers only
                </small>
                {isViewMode ? (
                  <div>{getValues("LearningIndicator")}</div>
                ) : (
                  <>
                    <Controller
                      name="LearningIndicator"
                      control={control}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          rows={3}
                          className="form-control"
                          id="LearningIndicator"
                        />
                      )}
                    />
                    <small className="m-0">
                      Suggested format: [subject]:[indicator_1],[indicator_2]
                      e.g. math:c1,a1
                    </small>
                  </>
                )}
              </div>
              <div hidden={isParent} className="mb-3">
                <Label>Teacher note</Label>
                <small className="m-2 text-muted">
                  visible to teachers only
                </small>
                {isViewMode ? (
                  <div>{getValues("TeacherNote")}</div>
                ) : (
                  <Controller
                    name="TeacherNote"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id="TeacherNote"
                      />
                    )}
                  />
                )}
              </div>
              <div hidden={isParent} className="mb-3">
                <Label>Supplies list</Label>
                <small className="m-2 text-muted">
                  visible to teachers only
                </small>
                {isViewMode ? (
                  <div>{getValues("SuppliesList")}</div>
                ) : (
                  <Controller
                    name="SuppliesList"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id="SuppliesList"
                      />
                    )}
                  />
                )}
              </div>
            </Col>
          </Row>
          {isViewMode ? (
            ""
          ) : (
            <FileUpload ControlID="Attachment" Accept={ValidExtensions} />
          )}
          {isViewMode ? <Label>Attachments</Label> : ""}
          {getValues("Attachments") &&
            (getValues("Attachments").filter(x => x.Active === true) || []).map(
              (attachment, e) => {
                return (
                  <div key={"Attachment" + e}>
                    <div className="d-flex align-start mb-2">
                      <div className="flex-grow-1">
                        <span>{attachment?.FileName}</span>
                      </div>
                      <Link
                        to="#"
                        className="text-muted"
                        onClick={() => {
                          downloadFile(attachment)
                        }}
                      >
                        <i
                          className="mdi mdi-download font-size-18"
                          id="downloadtooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="downloadtooltip"
                        >
                          Download
                        </UncontrolledTooltip>
                      </Link>
                      <Link
                        to="#"
                        hidden={isViewMode}
                        className="text-danger"
                        onClick={() => {
                          removeFile(attachment?.AttachmentID)
                        }}
                      >
                        <i
                          className="bx bx-trash font-size-18"
                          id="deletetooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="deletetooltip"
                        >
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                  </div>
                )
              }
            )}
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            hidden={isViewMode}
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            {isEditMode ? "Update" : "Add"}
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            {isViewMode ? "Close" : "Cancel"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ActivityModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isParent: PropTypes.bool,
}

export default ActivityModal
