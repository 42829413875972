import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import AdultSaga from "./adult/saga"
import RoleSaga from "./role/saga"
import ChildSaga from "./child/saga"
import ChildScheduleSaga from "./scheduling/child/saga"
import ClassSaga from "./class/saga"
import ClassScheduleSaga from "./scheduling/class/saga"
import CertificationSaga from "./certification/saga"
import DocumentSaga from "./document/saga"
import ImmunizationSaga from "./immunization/saga"
import MealSaga from "./meal/saga"
import MealScheduleSaga from "./scheduling/meal/saga"
import BusSaga from "./bus/saga"
import BusScheduleSaga from "./scheduling/bus/saga"
import RoomSaga from "./room/saga"
import StaffSaga from "./staff/saga"
import StaffScheduleSaga from "./scheduling/staff/saga"
import NotificationSaga from "./notification/saga"
import ForumSaga from "./forum/saga"
import ChatSaga from "./chat/saga"
import ProgramSaga from "./pricing/program/saga"
import FlexibleSaga from "./pricing/flexible/saga"
import NavigationSaga from "./navigation/saga"
import addonSaga from "./pricing/addon/saga"
import CenterProfileSaga from "./profile/saga"
import chargeSaga from "./pricing/charges/saga"
import AlbumSaga from "./album/saga"
import lessonPlanSaga from "./learning/lessonplan/saga"
import curriculumSaga from "./learning/curriculum/saga"
import noteSaga from "./learning/note/saga"
import resourceSaga from "./learning/resource/saga"
import reportSaga from "./report/saga"
import dashboardSaga from "./dashboard/saga"
import userSaga from "./user/saga"
import calendarSaga from "./calendar/saga"
import walletSaga from "./wallet/saga"
import activitySaga from "./scheduling/activity/saga"
import feeSaga from "./fee/saga"
import moduleSaga from "./module/saga"
import helperSaga from "./helper/saga"
import doctorScheduleSaga from "./scheduling/doctor/saga"
import externalSaga from "./external/saga"
import subscriptionSaga from "./subscription/saga"
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(RoleSaga),
    fork(AdultSaga),
    fork(ChildSaga),
    fork(ChildScheduleSaga),
    fork(ClassSaga),
    fork(ClassScheduleSaga),
    fork(CertificationSaga),
    fork(DocumentSaga),
    fork(ImmunizationSaga),
    fork(MealSaga),
    fork(MealScheduleSaga),
    fork(RoomSaga),
    fork(BusSaga),
    fork(BusScheduleSaga),
    fork(StaffSaga),
    fork(StaffScheduleSaga),
    fork(NotificationSaga),
    fork(ForumSaga),
    fork(ChatSaga),
    fork(ProgramSaga),
    fork(FlexibleSaga),
    fork(addonSaga),
    fork(chargeSaga),
    fork(NavigationSaga),
    fork(CenterProfileSaga),
    fork(AlbumSaga),
    fork(lessonPlanSaga),
    fork(curriculumSaga),
    fork(noteSaga),
    fork(resourceSaga),
    fork(reportSaga),
    fork(dashboardSaga),
    fork(userSaga),
    fork(calendarSaga),
    fork(walletSaga),
    fork(activitySaga),
    fork(feeSaga),
    fork(moduleSaga),
    fork(helperSaga),
    fork(doctorScheduleSaga),
    fork(externalSaga),
    fork(subscriptionSaga),
  ])
}
