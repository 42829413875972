import PropTypes, { object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"
import Flatpickr from "react-flatpickr"
import {
  addNewSubscription as onAddNewSubscription,
  getSubscriptionTypes as onGetSubscriptionTypes,
} from "store/actions"
import { isEmpty } from "lodash"

const formdefault = {
  SubscriptionTypes: "",
  StartDate: "",
  EndDate: "",
  IsActive: true,
}

const SubscriptionModal = ({ show, onEdit, onCloseClick }) => {
  const dispatch = useDispatch()
  const { subscriptiontypes, loading } = useSelector(
    state => state.subscription
  )
  /**
   * Form Validation Schema
   */

  const schema = yup.object().shape({
    SubscriptionTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    StartDate: yup.string().required("Required"),
    EndDate: yup.string().required("Required"),
    IsActive: yup.bool().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetSubscriptionTypes())
    }

    updateState()
  }, [dispatch, subscriptiontypes])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddNewSubscription(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Add Subscription
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Type</Label>
                <Controller
                  name="SubscriptionTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="SubscriptionTypes"
                        options={subscriptiontypes}
                        getOptionLabel={option => option.SubscriptionType}
                        getOptionValue={option => option.SubscriptionTypeID}
                        required
                        aria-invalid={!!errors.SubscriptionTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.SubscriptionTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.SubscriptionTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Start Date</Label>
                <Controller
                  name="StartDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="StartDate"
                        options={{
                          dateFormat: "d M, Y",
                          maxDate: getValues("EndDate"),
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("StartDate", dateStr)
                        }}
                      />
                      {errors?.StartDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.StartDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>End Date</Label>
                <Controller
                  name="EndDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="EndDate"
                        options={{
                          dateFormat: "d M, Y",
                          minDate: getValues("StartDate"),
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("EndDate", dateStr)
                        }}
                      />
                      {errors?.EndDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.EndDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Active</Label>
                <div>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsActive"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

SubscriptionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default SubscriptionModal
