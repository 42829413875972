import PropTypes, { number, object } from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"

//Date filter
import Moment from "moment"

import { addMessage as onAddMessage } from "store/actions"
import { isEmpty } from "lodash"
import FileUpload from "components/Common/FileUpload"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
import { useUserDetail } from "hooks"
import { Link } from "react-router-dom"

const formdefault = {
  ChildID: 0,
  Topic: "",
  Message: "",
  Announcement: false,
}

const MessageModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const { userprofile } = useUserDetail()

  const { child } = useSelector(state => state.child)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ChildID: yup.number(),
    Topic: yup.string().required("Required"),
    Message: yup.string().required("Required"),
    Announcement: yup.bool(),
    Attachment: yup.array().of(
      yup
        .mixed()
        .test("is-valid-type", "Not a valid file type", value => {
          if (!value) return true // Allow empty values
          return (
            isValidFileType(value && value.name.toLowerCase(), "file") ||
            isValidFileType(value && value.name.toLowerCase(), "image")
          )
        })
        .test("is-valid-size", "Max allowed size is 5MB", value => {
          if (!value) return true // Allow empty values
          return value.size <= MAX_FILE_SIZE // Convert KB to bytes
        })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    let response = {
      ...formdefault,
      ChildID: child.ChildID,
    }
    reset(response)
  }, [])

  const onSaveClick = e => {
    // console.log(getValues())
    trigger()
    if (isValid) {
      let request = {
        ...getValues(),
        ApprovalRequired: e,
      }

      dispatch(onAddMessage(request))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Message {userprofile?.RoleID === 3 ? child.Name : "Family"}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Topic</Label>
                <Controller
                  name="Topic"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="Topic"
                        type="text"
                        required
                        invalid={!!errors.Topic}
                      />
                      {errors?.Topic?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Topic?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Message</Label>
                <Controller
                  name="Message"
                  control={control}
                  render={({ field }) => (
                    <>
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control"
                        id="Message"
                        aria-invalid={!!errors.Message}
                      />
                      {errors?.Message?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Message?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div>
                <FileUpload ControlID="Attachment" Accept={ValidExtensions} />
              </div>
              <div className="mb-3" hidden={userprofile?.RoleID === 3}>
                <Label>Announcement</Label>
                <div>
                  <Controller
                    name="Announcement"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="Announcement"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <Button
            type="button"
            color="btn btn-outline-dark"
            onClick={onCloseClick}
          >
            Cancel
          </Button>
          <Button
            type="button"
            color="btn btn-outline-primary"
            onClick={() => onSaveClick(false)}
          >
            {userprofile?.RoleID === 3 ? "Send" : "Send & Release"}
          </Button>
          <Button
            type="button"
            color="btn btn-outline-warning"
            onClick={() => onSaveClick(true)}
            hidden={userprofile?.RoleID === 3}
          >
            Send for Review
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

MessageModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
}

export default MessageModal
