import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_IMPERSONATE_USER,
  UPDATE_IMPERSONATE_USER_FAIL,
  UPDATE_IMPERSONATE_USER_SUCCESS,
  DELETE_IMPERSONATE_USER,
  DELETE_IMPERSONATE_USER_FAIL,
  DELETE_IMPERSONATE_USER_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  ADD_LMS_PROFILE,
  ADD_LMS_PROFILE_SUCCESS,
  ADD_LMS_PROFILE_FAIL,
} from "./actionTypes"

export const getProfile = rowID => ({
  type: GET_PROFILE,
  payload: rowID,
})

export const getProfileSuccess = profile => ({
  type: GET_PROFILE_SUCCESS,
  payload: profile,
})

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
})

export const updateImpersonateUser = userID => ({
  type: UPDATE_IMPERSONATE_USER,
  payload: userID,
})

export const updateImpersonateUserSuccess = userprofile => ({
  type: UPDATE_IMPERSONATE_USER_SUCCESS,
  payload: userprofile,
})

export const updateImpersonateUserFail = error => ({
  type: UPDATE_IMPERSONATE_USER_FAIL,
  payload: error,
})

export const deleteImpersonateUser = userID => ({
  type: DELETE_IMPERSONATE_USER,
  payload: userID,
})

export const deleteImpersonateUserSuccess = userprofile => ({
  type: DELETE_IMPERSONATE_USER_SUCCESS,
  payload: userprofile,
})

export const deleteImpersonateUserFail = error => ({
  type: DELETE_IMPERSONATE_USER_FAIL,
  payload: error,
})

export const resetUserPassword = email => ({
  type: RESET_USER_PASSWORD,
  payload: email,
})

export const resetUserPasswordSuccess = email => ({
  type: RESET_USER_PASSWORD_SUCCESS,
  payload: email,
})

export const resetUserPasswordFail = error => ({
  type: RESET_USER_PASSWORD_FAIL,
  payload: error,
})

export const addLMSProfile = userID => ({
  type: ADD_LMS_PROFILE,
  payload: userID,
})

export const addLMSProfileSuccess = lmsRowID => ({
  type: ADD_LMS_PROFILE_SUCCESS,
  payload: lmsRowID,
})

export const addLMSProfileFail = error => ({
  type: ADD_LMS_PROFILE_FAIL,
  payload: error,
})
